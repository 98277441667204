import { mediaQuery } from '../../styles/helpers';
import { breakpoints } from '../../styles/constants';

const styles = () => ({
  options: {
    textAlign: 'left',
    ...mediaQuery.max(breakpoints.mediumMobile, {
      alignItems: 'inherit!important',
    }),
  },
  radioButton: {
    marginRight: '20px',
  },
});

export default styles;
