import React from 'react';
import PropTypes from 'prop-types';

export const isEmailValue = item => /\S+@\S+\.\S+/.test(item);

export const isValidUrl = item => {
  let url;
  try {
    url = new URL(item);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

const displayEmailItem = (item, classes) => (
  <>
    <span>E-Mail: </span>
    <a className={classes.link} href={`mailto:${item}`}>{item}</a>
  </>
);

const displayUrlItem = (item, classes) => (
  <>
    <span>www: </span>
    <a className={classes.link} href={item} target="_blank" rel="noopener noreferrer">{item}</a>
  </>
);

const displayText = (item) => (<p>{item}</p>);

const renderKeyMap = {
  email: 'email',
  url: 'url',
  text: 'text',
};

const renderFunctionMap = {
  [renderKeyMap.email]: displayEmailItem,
  [renderKeyMap.url]: displayUrlItem,
  [renderKeyMap.text]: displayText,
};

const renderItem = (item, classes) => {
  let type;
  switch (true) {
    case isEmailValue(item):
      type = renderKeyMap.email;
      break;
    case isValidUrl(item):
      type = renderKeyMap.url;
      break;
    default:
      type = renderKeyMap.text;
  }
  return renderFunctionMap[type](item, classes);
};

const List = ({ items, classes }) => {
  const listItems = items.map((item) => (
    <li key={item.toString()}>
      {renderItem(item, classes)}
    </li>
  ));

  const classNames = [classes.list, classes.contentItem].join(' ');

  return (
    <ul className={classNames}>{listItems}</ul>
  );
};

List.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  items: PropTypes.arrayOf(PropTypes.string),
};

List.defaultProps = {
  items: [],
};

export default List;
