const styles = theme => ({
  icon: {
    width: '50px',
    height: '50px',
    color: theme.colors.expandedIconBlack,
  },

  toggleLabel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '16px',
    color: theme.colors.themeDarkestGray,
    padding: '20px 0',
  },

  wrapper: {
    padding: '20px 8px 0',
  },
});

export default styles;
