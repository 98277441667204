import * as R from 'ramda';
import flowConfig from '../flow';
import paths from './paths';

export const NAV_DELAY = 500;

export const trimFlow = (formData, flow) => {
  const activeExcludes = R.pipe(
    R.reduce((acc, s) => R.concat(R.filter(R.propEq('key', formData[s.name]), s.options || []), acc), []),
    R.pluck('exclude'),
    R.filter(R.identity),
    R.flatten,
  )(flow);

  const activeIncludes = R.pipe(
    R.reduce(
      (acc, s) => R.concat(
        R.filter(
          o => (Array.isArray(formData[s.name]) ? !formData[s.name].includes(o.key) : formData[s.name] !== o.key),
          s.options || [],
        ),
        acc,
      ),
      [],
    ),
    R.pluck('include'),
    R.filter(R.identity),
    R.flatten,
  )(flow);

  return R.pipe(
    R.filter(step => step.name),
    R.filter(step => R.none(
      R.anyPass([R.equals(step.name), excludedName => R.startsWith(`${excludedName}_`, step.name)]),
      R.concat(activeExcludes, activeIncludes),
    )),
  )(flow);
};

export const getFlowHistory = (currentStep, pathName, formData) => {
  const trimmedFlow = trimFlow(formData || {}, flowConfig[pathName] || {});
  const stepIndex = R.findIndex(R.propEq('name', currentStep), trimmedFlow);

  return R.pipe(
    R.slice(0, stepIndex + 1),
    R.pluck('name'),
  )(trimmedFlow);
};

export const getPathByStepName = (match, stepName) => `/schadenmeldung/claim/${match.params.pathName}/${stepName}`;

export const makePath = ({ name, pathName }, direction, trimmedFlow) => {
  const stepIndex = R.findIndex(R.propEq('name', name), trimmedFlow);
  const i = stepIndex > -1 ? stepIndex + direction : stepIndex;
  return i < trimmedFlow.length && i >= 0 ? getPathByStepName({ params: { pathName } }, trimmedFlow[i].name) : '/';
};

export const navigateToPath = ({ params, summaryPath }, history, formData, previous) => {
  const path = summaryPath
    ? getPathByStepName({ params }, summaryPath)
    : makePath(params, previous ? -1 : 1, trimFlow(formData[params.pathName] || {}, flowConfig[params.pathName]));

  history.push(path);
};

export const navigateToBeginning = (history, pathName) => {
  // this forces the flow to be selected otherwise redirect to home which renders 404
  const toWhere = pathName ? paths.CLAIM_START.replace(':pathName', pathName) : paths.HOME;
  history.replace(toWhere);
};

export const shouldRedirectToBeginning = (storeParams, windowParams, lastVisitedLocation) => {
  // don't redirect if you're at the beginning
  const initialStep = () => {
    if (flowConfig[windowParams.pathName]) {
      return flowConfig[windowParams.pathName][0].name;
    }
    return undefined;
  };

  // so we don't loop when path is / or /claim
  if (windowParams.pathName === paths.HOME_NO_SLASH || window.pathName === paths.CLAIM_PARTIAL) {
    return false;
  }

  if (initialStep() === windowParams.name) {
    return false;
  }

  const isStateInitial = R.isEmpty(storeParams);
  const hasLocationChanged = lastVisitedLocation && !R.equals(windowParams, lastVisitedLocation);

  // check if somebody arrived in the middle and has data (or not yet initialized data)
  if (isStateInitial && (lastVisitedLocation && windowParams.pathName === lastVisitedLocation.pathName)) {
    // in case somebody's trying to go further in flow than it should be
    if (lastVisitedLocation.name !== windowParams.name) {
      return true;
    }
    // this allows to initial page load to pass (we don't have store yet)
    return false;
  }

  // this covers situation when page reloads or somebody uses navigation (otherwise it will force to start)
  if (!isStateInitial && lastVisitedLocation) {
    if (initialStep() && lastVisitedLocation.name !== initialStep()) {
      if (lastVisitedLocation.name !== storeParams.name && lastVisitedLocation.name !== windowParams.name) {
        return true;
      }
    }
  }

  // default behavior covering hasLocationChanged === null
  return isStateInitial && !hasLocationChanged ? true : !!(isStateInitial && hasLocationChanged);
};
