import {
  getOneFromMultiProxy,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { stolenCarPartsValues, theftDamages } from '../../../helpers/formData/vehicleFormData';

const getStolenCarPartsValues = formValue => {
  const labels = stolenCarPartsValues;
  const damagedParts = formValue.reduce((acc, value) => {
    acc.push(labels[value]);
    return acc;
  }, []);
  return damagedParts;
};

export default {
  'theft_kind-of-damage': [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      car: theftDamages.CAR,
      parts: theftDamages.PARTS,
    }),
  ],
  'theft_car-parts': [
    claimsKeys.STOLEN_CAR_PARTS,
    getStolenCarPartsValues,
  ],
};
