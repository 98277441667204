import PropTypes from 'prop-types';

const props = {
  classes: PropTypes.objectOf(PropTypes.string),
};

const defaults = {
  classes: {},
};

export default { props, defaults };
