import PropTypes from 'prop-types';

const props = {
  localePath: PropTypes.string,
  pathName: PropTypes.string,
  setNavigationState: PropTypes.func,
  stopLoading: PropTypes.func,
  startLoading: PropTypes.func,
  formData: PropTypes.objectOf(PropTypes.object),
  classes: PropTypes.objectOf(PropTypes.string),
  isSuccess: PropTypes.bool,
};

const defaults = {
  localePath: '',
  pathName: '',
  setNavigationState: () => {},
  stopLoading: () => {},
  startLoading: () => {},
  formData: {},
  classes: {},
  isSuccess: true,
};

export default {
  props,
  defaults,
};
