import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import HeadingForm from '../../layout/HeadingForm';
import RepairShopSelect from './RepairShopSelect';

import { fetchWorkshops as fetchWorkshopsAction } from '../../actions/workshops';
import { navigateToPath, setNavigationState as setNavigationStateAction } from '../../actions/navigation';
import { NAV_DIRECTIONS } from '../../actions/constants';


const states = {
  FETCHING: 'fetching',
  SELECTION: 'selection',
  NO_DATA: 'no-data',
  ERROR: 'error',
};

export const getCurrentState = (props) => {
  const { isFetching, fetchError, workshopList } = props;
  if (isFetching) {
    return states.FETCHING;
  }
  if (fetchError) {
    return states.ERROR;
  }
  if (workshopList.length === 0) {
    return states.NO_DATA;
  }
  return states.SELECTION;
};

class RepairShop extends React.Component {
  componentDidMount() {
    const {
      lastFetchedPostalCode,
      typedPostalCode,
      fetchWorkshops,
      workshopList,
      pathName,
      name,
      onChange,
    } = this.props;

    if (workshopList.length === 0 || typedPostalCode !== lastFetchedPostalCode) {
      onChange({ target: { name, type: 'text', value: undefined } });
      fetchWorkshops(typedPostalCode, pathName, name);
    }
  }

  shouldComponentUpdate(nextProps) {
    const { setNavigationState } = this.props;
    const currentState = getCurrentState(nextProps);
    const isNextActive = [states.ERROR, states.NO_DATA].includes(currentState)
      || (currentState === states.SELECTION && !isEmpty(nextProps.value));
    setNavigationState(isNextActive, true);
    return true;
  }

  content(currentState) {
    const {
      workshopList,
      goForward,
      value,
      name,
      pathName,
      onChange,
    } = this.props;

    if (currentState === states.SELECTION) {
      return (
        <RepairShopSelect
          workshopList={workshopList}
          selected={value}
          name={name}
          onChange={onChange}
          goForward={goForward}
          pathName={pathName}
        />
      );
    }
    return null;
  }

  render() {
    const { t } = this.context;
    const {
      lastFetchedPostalCode,
      localePath,
      isValid,
    } = this.props;

    const currentState = getCurrentState(this.props);
    return (
      <ContentWrapper
        isButtonDisabled={!isValid}
      >
        <HeadingForm
          value={t(`${localePath}.heading.${currentState}`).replace('{postalCode}', lastFetchedPostalCode)}
          commentValue={t(`${localePath}.comment.${currentState}`)}
        />
        { this.content(currentState) }
      </ContentWrapper>
    );
  }
}

RepairShop.propTypes = {
  workshopList: PropTypes.arrayOf(PropTypes.object),
  lastFetchedPostalCode: PropTypes.string,
  typedPostalCode: PropTypes.string,
  fetchWorkshops: PropTypes.func,
  goForward: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.objectOf(PropTypes.string),
  name: PropTypes.string,
  pathName: PropTypes.string,
  isValid: PropTypes.bool,
  localePath: PropTypes.string,
  setNavigationState: PropTypes.func,
};

RepairShop.defaultProps = {
  workshopList: [],
  lastFetchedPostalCode: '',
  typedPostalCode: '',
  fetchWorkshops: () => {},
  goForward: () => {},
  onChange: () => {},
  value: {},
  name: '',
  pathName: '',
  isValid: false,
  localePath: '',
  setNavigationState: () => {},
};

RepairShop.contextTypes = {
  t: PropTypes.func,
};

/* istanbul ignore next */
const mapStateToProps = ({ workshops }, ownProps) => (
  {
    ...workshops,
    value: ownProps.values[ownProps.name],
  }
);
/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  fetchWorkshops: (postalCode, pathName, fieldName) => dispatch(fetchWorkshopsAction(postalCode, pathName, fieldName)),
  goForward: () => dispatch(navigateToPath(NAV_DIRECTIONS.NEXT)),
  goBack: () => dispatch(navigateToPath(NAV_DIRECTIONS.PREVIOUS)),
  setNavigationState: (isNextActive, isPreviousActive) => {
    dispatch(setNavigationStateAction(isNextActive, isPreviousActive));
  },
});

export { RepairShop as RepairShopPlain };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RepairShop);
