import PropTypes from 'prop-types';

const validationTypes = {
  ACCEPTED_FILE_EXTENSIONS: [
    '.heic',
    '.pdf',
    '.jpeg',
    '.jpg',
    '.tif',
    '.tiff',
    '.png',
    '.bmp',
    '.HEIC',
    '.PNG',
    '.BMP',
    '.TIFF',
    '.TIF',
    '.JPG',
    '.JPEG',
    '.PDF',
  ].join(','),
  UPLOADED_FILES_NUMBER_LIMIT: 10,
  ACCEPTED_FILE_SIZE: 5,
  MAXIMUM_FILENAME_LENGTH: 255,
  // checks for (& / \ | ' * ; " > < % ?)
  DISALLOWED_FILENAME_CHARS: /(\(|\)|&|\/|\\|\||'|\*|;|"|>|<|%|\?)/miu,
};

const fileResource = PropTypes.shape({
  location: PropTypes.string,
  fileName: PropTypes.string,
});

const props = {
  classes: PropTypes.objectOf(PropTypes.string),
  localePath: PropTypes.string,
  pathName: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.arrayOf(fileResource),
  accept: PropTypes.string,
  getValidationProps: PropTypes.func,
  addFile: PropTypes.func,
  removeFile: PropTypes.func,
};

const defaults = {
  classes: {},
  localePath: '',
  pathName: '',
  name: '',
  data: [],
  accept: validationTypes.ACCEPTED_FILE_EXTENSIONS,
  getValidationProps: () => {},
  addFile: () => {},
  removeFile: () => {},
};

export default {
  props,
  defaults,
  validationTypes,
};
