import { mediaQuery } from '../../styles/helpers';
import { breakpoints } from '../../styles/constants';

const nrwUrls = [
  'emil-frey-autoversicherung.mobility-insurance.de',
  'emil-frey-autoversicherung.de',
  'www.emil-frey-autoversicherung.de',
  'nrw-garage-autoversicherung.de',
  'www.nrw-garage-autoversicherung.de',
  'staging-nrw-garage.aws-0211.cloudfirst.digital',
  'master-nrw-garage.aws-0211.cloudfirst.digital',
  'test-nrw-garage.aws-0211.cloudfirst.digital',
  'nrwgarage-autoversicherung.ems-prod-canary.mobility-insurance.de',
  'nrwgarage-autoversicherung.mobility-insurance.de',
];

const bmwUrls = [
  'fahrfreude-versicherung.ems-prod-canary.mobility-insurance.de',
  'fahrfreude-versicherung.mobility-insurance.de',
  'ems-fahrfreude-versicherung.de',
];

const hyundaiUrls = [
  'hyundai.ems-prod-canary.mobility-insurance.de',
  'hyundai.mobility-insurance.de',
  'hyundai-protect-autoversicherung.de',
  'www.hyundai-protect-autoversicherung.de',
];

const volvoUrls = [
  'ems-mobility-insurance.de',
];

const empUrls = [
  'localhost:3001',
  'ergo-mobility-protect.de',
  'www.ergo-mobility-protect.de',
];

const HOST_WITH_CLOUD_FIRST = '.cloudfirst.digital';

const ORGANIZATIONS = {
  VOLVO: 'volvo',
  NRW_GARAGE: 'nrw',
  BMW: 'bmw',
  HYUNDAI: 'hyundai',
  EMP: 'emp',
  THIRD_PARTY: 'third-party',
};

const MAIN_COLORS = {
  VOLVO: '#012C46',
  NRW_GARAGE: '#000000',
  BMW: '#103C7F',
  HYUNDAI: '#022D60',
  EMP: '#29274C',
  THIRD_PARTY: '#035496',
};

const OPTIONS = {
  THEME: 'theme',
  PHONE: 'phone',
  LOGO: 'logo',
};

const thirdPartyTheme = {
  topBarBackground: {
    display: 'none',
  },
  logo: {
    display: 'none',
  },
  footerBackground: {
    backgroundColor: MAIN_COLORS.THIRD_PARTY,
  },
};

const safariLogoFix = {
  objectFit: 'contain',
  objectPosition: '0 0',
};

const OrganizationDetails = {
  [ORGANIZATIONS.VOLVO]: {
    [OPTIONS.THEME]: thirdPartyTheme,
    [OPTIONS.PHONE]: '0800/ 5551070',
    [OPTIONS.LOGO]: '',
  },
  [ORGANIZATIONS.NRW_GARAGE]: {
    [OPTIONS.THEME]: {
      topBarBackground: {
        background: MAIN_COLORS.NRW_GARAGE,
      },
      logo: {
        padding: '10px 0 10px 50px',
        ...mediaQuery.max(breakpoints.mediumMobile, {
          paddingLeft: '10px',
        }),
        ...safariLogoFix,
      },
      footerBackground: {
        backgroundColor: MAIN_COLORS.NRW_GARAGE,
      },
    },
    [OPTIONS.PHONE]: '0800/ 5552070',
    [OPTIONS.LOGO]: '/schadenmeldung/nrw.png',
  },
  [ORGANIZATIONS.BMW]: {
    [OPTIONS.THEME]: {
      topBarBackground: {
        background: 'linear-gradient(270deg, #082854 0.02%, #1E65D5 41.92%, #FFFFFF 100%)',
      },
      logo: {
        padding: '11px 0 11px 30px',
        ...safariLogoFix,
      },
      footerBackground: {
        backgroundColor: MAIN_COLORS.BMW,
      },
    },
    [OPTIONS.PHONE]: '0800/ 8882060',
    [OPTIONS.LOGO]: '/schadenmeldung/bmw.png',
  },
  [ORGANIZATIONS.HYUNDAI]: {
    [OPTIONS.THEME]: {
      topBarBackground: {
        backgroundImage: 'linear-gradient(0deg, rgb(201, 212, 224) 1%, rgb(254, 254, 255) 46%, #FFFFFF 100%)',
      },
      logo: {
        padding: '26px 0 26px 50px',
        ...safariLogoFix,
      },
      footerBackground: {
        backgroundColor: MAIN_COLORS.HYUNDAI,
      },
    },
    [OPTIONS.PHONE]: '0800/ 8881060',
    [OPTIONS.LOGO]: '/schadenmeldung/hyundai.png',
  },
  [ORGANIZATIONS.EMP]: {
    [OPTIONS.THEME]: {
      topBarBackground: {
        background: '#000000',
      },
      logo: {
        padding: '18px 0 18px 50px',
        ...safariLogoFix,
      },
      footerBackground: {
        backgroundColor: MAIN_COLORS.EMP,
      },
    },
    [OPTIONS.PHONE]: '0800/ 3746206',
    [OPTIONS.LOGO]: '/schadenmeldung/emp.png',
  },
  [ORGANIZATIONS.THIRD_PARTY]: {
    [OPTIONS.THEME]: thirdPartyTheme,
    [OPTIONS.PHONE]: '0341/ 98993570',
    [OPTIONS.LOGO]: '',
  },
};

const getCurrentOrganizationName = () => {
  const { host } = window.location;

  if (nrwUrls.includes(host) || (host.includes(ORGANIZATIONS.NRW_GARAGE) && host.includes(HOST_WITH_CLOUD_FIRST))) {
    return ORGANIZATIONS.NRW_GARAGE;
  }

  if (bmwUrls.includes(host) || (host.includes(ORGANIZATIONS.BMW) && host.includes(HOST_WITH_CLOUD_FIRST))) {
    return ORGANIZATIONS.BMW;
  }

  if (hyundaiUrls.includes(host) || (host.includes(ORGANIZATIONS.HYUNDAI) && host.includes(HOST_WITH_CLOUD_FIRST))) {
    return ORGANIZATIONS.HYUNDAI;
  }

  if (empUrls.includes(host) || (host.includes(ORGANIZATIONS.EMP) && host.includes(HOST_WITH_CLOUD_FIRST))) {
    return ORGANIZATIONS.EMP;
  }

  if (volvoUrls.includes(host) || (host.includes(ORGANIZATIONS.VOLVO) && host.includes(HOST_WITH_CLOUD_FIRST))) {
    return ORGANIZATIONS.VOLVO;
  }

  return ORGANIZATIONS.THIRD_PARTY;
};

const organizationName = getCurrentOrganizationName();

const getProperData = optionName => OrganizationDetails[organizationName][optionName];

const organizationTheme = getProperData(OPTIONS.THEME);
const phoneNumber = getProperData(OPTIONS.PHONE);
const logo = getProperData(OPTIONS.LOGO);

export {
  organizationTheme,
  ORGANIZATIONS,
  phoneNumber,
  organizationName,
  logo,
};
