import claimFire from './claimFire';
import claimTheft from './claimTheft';
import claimGlass from './claimGlass';
import claimAnimal from './claimAnimal';
import claimWeather from './claimWeather';
import claimCommon from './claimCommon';
import claimLiability from './claimLiability';
import claimCollision from './claimCollision';
import vehicleFlows from '../../../models/dataMappings/vehicleFlows';
import { claimDataTransform, PARTY } from '../claimData.helpers';
import { moduleNames } from '../claimData';
import { getAdditionalData } from '../../../helpers/formData/vehicleFormData';

const assignClaimData = (whatHappened, whoFills) => {
  if (whoFills === PARTY) {
    return claimLiability;
  }

  switch (whatHappened) {
    case vehicleFlows.ANIMAL:
      return claimAnimal;
    case vehicleFlows.WEATHER:
      return claimWeather;
    case vehicleFlows.FIRE:
      return claimFire;
    case vehicleFlows.GLASS:
      return claimGlass;
    case vehicleFlows.THEFT:
      return claimTheft;
    case vehicleFlows.COLLISION:
      return claimCollision;
    default:
      return {};
  }
};

const vehicleTransform = formData => {
  let claimData = assignClaimData(formData['what-happened'], formData['who-fills']);
  claimData = {
    ...claimCommon,
    ...claimData,
  };
  const data = claimDataTransform(claimData, formData);
  const additionalData = getAdditionalData(data);

  return {
    data,
    serviceType: moduleNames.VEHICLE,
    ...additionalData,
  };
};

export default vehicleTransform;
