import { addSegments } from '../../../helpers/flow/flow';
import flow from './main.yaml';

// paths
import animalDamage from './segments/animal-damage.yaml';
import fireOrExplosion from './segments/fire-or-explosion.yaml';
import theft from './segments/theft.yaml';
import vandalism from './segments/vandalism.yaml';
import glassDamage from './segments/glass-damage.yaml';
import weather from './segments/weather.yaml';
import collision from './segments/collision.yaml';
import liability from './segments/liability.yaml';

import identification from './segments/identification.yaml';
import liabilityIdentification from './segments/liability-identification.yaml';
import accidentDetails from './segments/accident-details.yaml';
import accidentDetailsLocation from './segments/accident-details-location.yaml';
import policeWithNoReport from './segments/police-with-no-report.yaml';
import policeWithReport from './segments/police-with-report.yaml';
import areThereAnyWitnesses from './segments/are-there-any-witnesses.yaml';
import witness from './segments/witness.yaml';
import driver from './segments/driver.yaml';
import policyholderDamages from './segments/policyholder-damages.yaml';
import repairedCar from './segments/repaired-car.yaml';
import thirdPartyDamages from './segments/third-party-damages.yaml';
import vehicleDamage from './segments/vehicle-damage.yaml';
import liabilityDamages from './segments/liability-damages.yaml';
import glassDamages from './segments/glass-damages.yaml';
import theftDamages from './segments/theft-damages.yaml';
import documentUpload from './segments/document-upload.yaml';

const segments = {
  animalDamage,
  fireOrExplosion,
  theft,
  vandalism,
  glassDamage,
  weather,
  collision,
  liability,
  // segments
  identification,
  liabilityIdentification,
  accidentDetails,
  accidentDetailsLocation,
  policeWithNoReport,
  policeWithReport,
  areThereAnyWitnesses,
  witness,
  driver,
  policyholderDamages,
  thirdPartyDamages,
  vehicleDamage,
  repairedCar,
  liabilityDamages,
  glassDamages,
  theftDamages,
  documentUpload,
};

export default addSegments(flow, segments);
