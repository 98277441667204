import claimCommon from '../vehicle/claimCommon';
import claimLiability from '../vehicle/claimLiability';
import { claimDataTransform } from '../claimData.helpers';
import { moduleNames } from '../claimData';
import { getAdditionalData } from '../../../helpers/formData/vehicleFormData';

const thirdPartyTransform = formData => {
  const claimData = {
    ...claimCommon,
    ...claimLiability,
  };
  const data = claimDataTransform(claimData, formData);
  const additionalData = getAdditionalData(data);

  return {
    data,
    serviceType: moduleNames.THIRD_PARTY,
    ...additionalData,
  };
};

export default thirdPartyTransform;
