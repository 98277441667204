import {
  __, contains, ifElse, append, without, mergeDeepRight,
} from 'ramda';
import { types } from '../../actions/constants';


export default (state = {}, action = { type: null }) => {
  switch (action.type) {
    case types.FIELD_UPDATE: {
      const { fieldName, payload, pathName } = action;
      const newState = {};
      const field = {};

      field[fieldName] = payload;
      newState[pathName] = field;

      return mergeDeepRight(state, newState);
    }

    case types.FIELD_UPDATE_MULTI: {
      const {
        fieldName, payload, pathName, subName, isString,
      } = action;
      const newState = {};
      const field = {};
      let answers;
      let answer;

      if (subName && !isString) {
        answers = state[pathName] && state[pathName][fieldName] && state[pathName][fieldName][subName];
      } else if (subName && isString) {
        answer = state[pathName] && state[pathName][fieldName] && state[pathName][fieldName][subName];
      } else {
        answers = state[pathName] && state[pathName][fieldName];
      }

      if (!answers) {
        answers = [];
      }

      if (!answer) {
        answer = '';
      }

      answers = ifElse(contains(__, answers), without(__, answers), append(__, answers))(payload);
      answer = payload;

      if (fieldName) {
        if (subName && !isString) {
          field[fieldName] = {
            [subName]: answers,
          };
        } else if (subName && isString) {
          field[fieldName] = {
            [subName]: answer,
          };
        } else {
          field[fieldName] = answers;
        }
      }
      newState[pathName] = field;
      return mergeDeepRight(state, newState);
    }

    case types.FILE_UPLOAD_ADD: {
      const { fieldName, payload, pathName } = action;
      const storedField = state[pathName] && state[pathName][fieldName];

      return mergeDeepRight(state, {
        [pathName]: {
          [fieldName]: storedField ? [...storedField, payload] : [payload],
        },
      });
    }

    case types.FILE_UPLOAD_REMOVE: {
      const { fieldName, payload, pathName } = action;
      let files = [];
      const storedField = state[pathName] && state[pathName][fieldName];

      if (storedField) {
        files = storedField.filter((file) => file.location !== payload);
      }

      return mergeDeepRight(state, {
        [pathName]: {
          [fieldName]: files,
        },
      });
    }

    case types.RESET_FORM_DATA: {
      return {};
    }

    case types.FIELD_REMOVE: {
      const { fieldName, pathName } = action;
      const {
        [pathName]: { [fieldName]: fieldToRemove, ...otherFieldsInPath },
        ...otherPaths
      } = state;

      return {
        [pathName]: {
          ...otherFieldsInPath,
        },
        ...otherPaths,
      };
    }

    default:
      return state;
  }
};
