import {
  getOneFromMultiProxy,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { glassDamages } from '../../../helpers/formData/vehicleFormData';

export default {
  'glass-damage_kind-of-damage': [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      'front-window': glassDamages.FRONT_WINDOW,
      'back-window': glassDamages.BACK_WINDOW,
      'side-window': glassDamages.SIDE_WINDOW,
      headlights: glassDamages.HEADLIGHTS,
      'roof-glazing': glassDamages.ROOF_GLAZING,
      'mirror-glass': glassDamages.MIRROR_GLASS,
    }),
  ],
};
