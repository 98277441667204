import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgSpinacz } from '../../helpers/IconLoader';
import { legalConsentProps } from '../../models/panels';
import { getFileDetails } from '../../helpers/formData/vehicleFormData';
import { getFileName, getFileExtension } from '../Upload/Preview';

// match first non-greedy
const splitPattern = /[_]/m;
const MAX_FILENAME_LENGTH = 20;

const parseFilename = filename => {
  const { index } = splitPattern.exec(filename);
  const name = filename.substr(index + 1, filename.length);
  const fileName = getFileName(name);
  const extension = getFileExtension(name);
  return fileName.length > MAX_FILENAME_LENGTH ? `${fileName.substr(0, MAX_FILENAME_LENGTH)}... .${extension}` : name;
};

const getFileInfo = (data = []) => data.join(', ');

const FileDetails = ({
  localePath, className, formData,
}, { t }) => {
  const { hasFiles, fileTypes, files } = getFileDetails(formData);
  if (!hasFiles) return false;

  return (
    <Details iconLeft={SvgSpinacz} summary={t(`${localePath}.docs.title`)} className={className}>
      <p>{t(`${localePath}.docs.content`)}</p>
      <p>{getFileInfo(fileTypes)}</p>
      {files.map(file => (
        <p key={file}>{decodeURIComponent(parseFilename(file))}</p>
      ))}
    </Details>
  );
};

FileDetails.propTypes = legalConsentProps.sectionProps;
FileDetails.defaultProps = legalConsentProps.sectionDefaults;

FileDetails.contextTypes = {
  t: PropTypes.func,
};

export default FileDetails;
