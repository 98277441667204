const styles = ({ colors, breakpoints }) => ({
  root: {
    paddingBottom: '40px',
  },
  rootPosition: {
    textAlign: 'left',
  },
  heading: {
    lineHeight: '42px',
    fontSize: '28px',
    fontWeight: 'bold',
    color: colors.themeBlack,
    [`@media (max-width: ${breakpoints.breakpointM})`]: {
      lineHeight: '16px',
      fontSize: '16px',
    },
  },
  commentHeading: {
    fontSize: '16px',
    color: colors.themeDarkestGray,
    lineHeight: '21px',
    paddingTop: 12,
    [`@media (max-width: ${breakpoints.breakpointM})`]: {
      fontSize: '14px',
      paddingTop: 8,
    },
  },
  multipleSelectWrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 12,
    [`@media (max-width: ${breakpoints.breakpointM})`]: {
      fontSize: '14px',
      paddingTop: 8,
    },
  },
  textMultipleSelect: {
    fontSize: 12,
    color: colors.themeDarkerGray,
    paddingTop: 6,
  },
  infoIcon: {
    paddingRight: 8,
    fill: `${colors.themeDarkerGray} !important`,
  },
  tooltip: {
    display: 'inline-block',
    verticalAlign: 'super',
    [`@media (max-width: ${breakpoints.breakpointM})`]: {
      verticalAlign: 'middle',
    },
  },
});

export default styles;
