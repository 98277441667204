const styles = theme => ({
  wrapper: {
    textAlign: 'left',
    padding: '16px 8px 40px 8px',
    height: '100%',
  },

  distance: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: theme.colors.themeAdditional1,
    lineHeight: '24px',
  },

  name: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.colors.themeDarkestGray,
    lineHeight: '24px',
    margin: '4px 0px',
  },

  street: {
    fontSize: '14px',
    color: theme.colors.themeDarkestGray,
    lineHeight: '21px',
  },
});

export default styles;
