import {
  getOneFromMultiProxy,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { weatherDamages } from '../../../helpers/formData/vehicleFormData';

export default {
  'damage-caused-by-weather_kind-of-damage': [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      lightning: weatherDamages.LIGHTNING,
      flood: weatherDamages.FLOOD,
      avalanche: weatherDamages.AVALANCHE,
      landslide: weatherDamages.LANDSLIDE,
      earthquake: weatherDamages.EARTHQUAKE,
      storm: weatherDamages.STORM,
      hail: weatherDamages.HAIL,
    }),
  ],
};
