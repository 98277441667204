const list = {
  position: 'relative',
  listStyleType: 'none',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
};
const listAfter = (color, breakpoint) => ({
  position: 'absolute',
  content: '""',
  width: 2,
  left: 29,
  height: 220,
  top: 1,
  zIndex: 9,
  background: color,
  [`@media (max-width: ${breakpoint})`]: {
    height: '88%',
  },
});

const styles = ({ colors, breakpoints }) => ({
  root: {
    marginTop: 16,
  },
  listShort: {
    ...list,
    '&:after': {
      ...listAfter(colors.themeDarkerGray, breakpoints.breakpointS),
      height: '99%',
    },
    marginBottom: 40,
  },
  additionalInfoText: {
    textAlign: 'left',
  },
});

export default styles;
