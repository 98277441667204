import { createStore, applyMiddleware, compose as composeWithRedux } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import throttle from 'lodash/throttle';
import thunkMiddleware from './middleware/thunkMiddleware';
import rootReducer from './reducers';
import navigationMiddleware from './middleware/navigation';
import { loadSessionState, saveSessionState, setLastVisitedLocation } from './services';
import { pickFormDataForStorage } from './helpers/formData/formData';

const persistedState = loadSessionState();

const isDevEnvironment = process.env && process.env.NODE_ENV === 'development';
const compose = isDevEnvironment ? composeWithDevTools : composeWithRedux;

const middleware = compose(applyMiddleware(thunkMiddleware, navigationMiddleware));

const store = createStore(
  rootReducer,
  persistedState,
  middleware,
);

store.subscribe(throttle(() => {
  const {
    formData,
    navigation,
    workshops,
  } = store.getState();
  const { pathName } = navigation.current.params;
  const data = pickFormDataForStorage(formData[pathName]);

  saveSessionState({
    formData: { ...formData, [pathName]: data },
    workshops,
  });
  if (navigation.current.params.name) {
    setLastVisitedLocation(navigation.current.params);
  }
}, 400));

export default store;
