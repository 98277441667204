import React from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';
import DatePickerInput from '../DatePickerInput';
import TimeInput from '../TimeInput';

const DateTime = (props, { t }) => {
  const {
    hideTime,
    localePath,
    getValidationProps,
    shrinkOnMobile,
    defaultYear,
  } = props;

  return (
    <div className="esc_grid">
      <div className="esc_grid__wrapper">
        <div className="esc_col esc_col-12 esc_col-s-4">
          <DatePickerInput
            description={t(`${localePath}.labels.dateDescription`)}
            type="text"
            placeholder={t(`${localePath}.labels.dateLabel`)}
            {...getValidationProps('date')}
            shrinkOnMobile={shrinkOnMobile}
            defaultYear={defaultYear}
          />
        </div>
        {!hideTime && (
          <>
            <TimeInput
              description={t(`${localePath}.labels.time-hh-description`)}
              placeholder={t(`${localePath}.labels.time-hh`)}
              maxValue={23}
              minValue={0}
              {...getValidationProps('hour')}
            />
            <TimeInput
              description={t(`${localePath}.labels.time-mm-description`)}
              placeholder={t(`${localePath}.labels.time-mm`)}
              maxValue={59}
              minValue={0}
              {...getValidationProps('min')}
            />
          </>
        )}
      </div>
    </div>
  );
};

DateTime.propTypes = {
  localePath: PropTypes.string,
  hideTime: PropTypes.bool,
  getValidationProps: PropTypes.func,
  shrinkOnMobile: PropTypes.bool,
  defaultYear: PropTypes.number,
};

DateTime.defaultProps = {
  localePath: '',
  hideTime: false,
  getValidationProps: () => {},
  shrinkOnMobile: false,
  defaultYear: undefined,
};

DateTime.contextTypes = {
  t: PropTypes.func,
};

export { DateTime as DateTimePlain };
export default compose(
  withMultiFieldValidation,
)(DateTime);
