export default async (componentProps, actionName) => {
  const { googleReCaptchaProps } = componentProps;
  if (!googleReCaptchaProps) {
    return '';
  }
  const { executeRecaptcha } = googleReCaptchaProps;
  if (!executeRecaptcha) {
    return '';
  }
  const token = await executeRecaptcha(actionName);
  return token;
};
