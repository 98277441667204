import React from 'react';
import PropTypes from 'prop-types';
import HeadingForm from '../../../layout/HeadingForm';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';

const Heading = ({
  children, buttonLabelText, headingText, commentText,
}) => (
  <ContentWrapper title={buttonLabelText} icon="">
    <HeadingForm
      value={headingText}
      commentValue={commentText}
    />
    {children}
  </ContentWrapper>
);

Heading.propTypes = {
  children: PropTypes.node,
  buttonLabelText: PropTypes.string,
  headingText: PropTypes.string,
  commentText: PropTypes.string,
};

Heading.defaultProps = {
  children: null,
  buttonLabelText: '',
  headingText: '',
  commentText: '',
};

export default Heading;
