import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';
import ErgoCountryAutocomplete from '../ErgoCountryAutocomplete';
import ErgoInputText from '../ErgoInputText';
import ErgoMaskedText from '../../panels/ErgoMaskedText';
import DatePickerInput from '../../panels/DatePickerInput';
import { formTypes, assignFormType } from '../../models/form';

const ErgoDataForm = (props, { t }) => {
  const { localePath, getValidationProps, formType } = props;
  const inputs = assignFormType(formType);

  return (
    <div className="esc_grid">
      <div className="esc_grid__wrapper">
        {Object.keys(inputs).map(inputName => {
          const input = inputs[inputName];
          const inputPlaceholder = t(`${localePath}.placeholders.${inputName}`);
          const inputDescription = t(`${localePath}.descriptions.${inputName}`);
          const { value, ...otherProps } = getValidationProps(inputName);

          const validationProps = input.uppercase
            ? { value: (value || '').toUpperCase(), ...otherProps }
            : { value, ...otherProps };

          if (input.mask) {
            return (
              <div key={inputName} className={`esc_col esc_col-12 esc_col-s-${input.size}`}>
                <ErgoMaskedText
                  type="text"
                  placeholder={inputPlaceholder}
                  mask={input.mask}
                  formatCharacters={input.formatCharacters}
                  inputSize={input.size}
                  {...validationProps}
                />
              </div>
            );
          }

          if (input.autocomplete) {
            return (
              <div key={inputName} className={`esc_col esc_col-12 esc_col-s-${input.size}`}>
                <ErgoCountryAutocomplete
                  {...validationProps}
                  placeholder={inputPlaceholder}
                  description={inputDescription}
                />
              </div>
            );
          }

          if (input.onlyInRow) {
            return (
              <div key={inputName} className="esc_col-12">
                <div className={`esc_col esc_col-12 esc_col-s-${input.size}`}>
                  <ErgoInputText {...validationProps} placeholder={inputPlaceholder} description={inputDescription} />
                </div>
              </div>
            );
          }

          if (input.calendar) {
            return (
              <div key={inputName} className={`esc_col esc_col-12 esc_col-s-${input.size}`}>
                <DatePickerInput
                  {...validationProps}
                  placeholder={inputPlaceholder}
                  description={inputDescription}
                  defaultYear={input.defaultYear}
                />
              </div>
            );
          }

          return (
            <div key={inputName} className={`esc_col esc_col-12 esc_col-s-${input.size}`}>
              <ErgoInputText {...validationProps} placeholder={inputPlaceholder} description={inputDescription} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

ErgoDataForm.propTypes = {
  localePath: PropTypes.string,
  getValidationProps: PropTypes.func,
  formType: PropTypes.oneOf(Object.values(formTypes)),
};

ErgoDataForm.defaultProps = {
  localePath: '',
  getValidationProps: () => {},
  formType: formTypes.CONTACT_PERSON,
};

ErgoDataForm.contextTypes = {
  t: PropTypes.func,
};

export { ErgoDataForm as ErgoDataFormPlain };
export default compose(withMultiFieldValidation)(ErgoDataForm);
