import moment from 'moment';
import { getFlowHistory } from '../../helpers/navigation';
import { getTranslationArray } from '../../helpers/polyglot';
import globals from '../../globals';
import { documentTypes } from '../../helpers/formData/vehicleFormData';

export const YES = 'yes';
export const NO = 'no';
export const MY_ACCOUNT = 'my-account';
export const CAR = 'car';
export const POLICYHOLDER = 'policyholder';
export const PARTY = 'party';
export const INVALID_DATE = '00.00.0000';

export const getUploadValues = fileTypes => {
  if (!fileTypes) {
    return undefined;
  }

  const result = fileTypes.map(fileType => documentTypes[fileType]);
  return result;
};

export const getUploadedFiles = formValue => {
  if (!formValue) {
    return [];
  }
  return formValue.map(file => file.location);
};

export const getYesNoValue = (formValue, yesValue = YES) => (formValue === yesValue ? 'j' : 'n');

export const getPhoneValue = formValue => {
  if (!formValue || !formValue.phoneNumber) {
    return undefined;
  }
  const { phoneNumber } = formValue;
  return `${phoneNumber ? phoneNumber.trim() : ''}`.trim();
};

export const getDateTimeValue = formValue => {
  if (!formValue) {
    return {};
  }
  const { date, time } = formValue;
  return {
    date: date ? moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY') : undefined,
    time: time ? moment(time, 'hh:mm').format('HHmm') : undefined,
  };
};

export const getDateValue = date => (!date ? undefined : moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD'));

export const getTimeValue = (hours, minutes) => (
  !hours && !minutes ? '' : moment(`${hours}:${minutes}`, 'hh:mm').format('HH:mm:ss')
);

export const getFormattedTimeValue = data => moment(data, 'HHmm').format('hh:mm');

export const getFormattedDateValue = date => moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');

export const getCountryCode = formValue => {
  if (!formValue && !formValue.country) {
    return 'DE';
  }
  const countryList = getTranslationArray('dropdowns.countries');
  return countryList.find(obj => obj.name === formValue.country).value;
};

export const getIdentityValue = formValue => {
  const identityList = getTranslationArray('dropdowns.identities');
  return identityList.find(obj => obj.name === formValue.identity).value;
};

export const stripSpacesFromIban = formValue => formValue.iban.replace(/\s+/g, '');

export const getLicenseNumber = formValue => {
  const licenseNumber = Object.values(formValue);
  const formattedArray = licenseNumber.map(v => v.trim());
  return formattedArray.join(' ');
};

export const getCountryNameByCode = countryCode => {
  if (!countryCode) return '';
  const countryList = getTranslationArray('dropdowns.countries');
  return countryList.find(obj => obj.value === countryCode).name;
};

export const getSalutationValue = formValue => {
  const labels = getTranslationArray('common.salutation');
  return labels[formValue.who];
};

export const getCarMapValues = key => {
  const labels = getTranslationArray('common.car-map');
  return labels[key];
};

export const getCarMapValuesProxy = formValue => {
  const { carMap } = formValue;
  return (carMap && carMap.map(key => getCarMapValues(key))) || undefined;
};

export const getFirstNameValue = formValue => (formValue && formValue.firstName) || undefined;

export const getLastNameValue = formValue => (formValue && formValue.lastName) || undefined;

export const getOneFromMulti = (formValue, options) => options[formValue];

export const getCountryValue = formValue => (formValue && formValue.country) || 'DE';

export const getUploadedFilesProxy = formValue => getUploadedFiles(formValue);

export const getOneFromMultiProxy = options => formValue => getOneFromMulti(formValue, options);

export const getDateValueProxy = formValue => getDateValue(formValue.date);

export const getTimeValueProxy = formValue => getTimeValue(formValue.hour, formValue.min);

export const arrayIncludesValue = (formValue, value) => (formValue || []).includes(value);

export const arrayIncludesValueProxy = value => formValue => getYesNoValue(arrayIncludesValue(formValue, value), true);

export const claimDataTransform = (definition, formData) => {
  const params = globals.window.location.pathname.split('/');
  const currentStep = params.pop();
  const pathName = params.pop();
  const flowHistory = getFlowHistory(currentStep, pathName, formData);
  const claimData = flowHistory.reduce((acc, cur) => {
    if (definition[cur] && formData[cur]) {
      if (typeof definition[cur] === 'string') {
        acc[definition[cur]] = formData[cur];
      } else if (definition[cur] instanceof Array) {
        const key = definition[cur][0];
        const value = typeof definition[cur][1] === 'function' ? definition[cur][1](formData[cur]) : definition[cur][1];
        acc[key] = value;
      } else {
        Object.keys(definition[cur]).forEach(vName => {
          const v = definition[cur][vName];
          if (v[1] && typeof v[1] === 'function') {
            acc[v[0]] = v[1](formData[cur]);
          } else if (typeof v === 'string') {
            acc[v] = formData[cur][vName];
          }
        });
      }
    } else if (process.env.NODE_ENV === 'development') {
      console.log('missing mapper key', cur); // eslint-disable-line no-console
    }
    return acc;
  }, {});

  return claimData;
};
