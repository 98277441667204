import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import MaskedText from '../../MaskedText/MaskedText';

import styles from './styles.jss';

class DateRangeForm extends React.PureComponent {
  constructor(props) {
    super();

    this.state = props.data;
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e, name) {
    this.setState({
      [name]: e.target.value,
    }, () => {
      const { onChange } = this.props;
      onChange({ value: this.state });
    });
  }

  render() {
    const {
      classes,
      localePath,
    } = this.props;

    const {
      from,
      to,
    } = this.state;

    const { t } = this.context;

    return (
      <div className={classes.wrapperBox}>
        <span className={classes.labelBox}>
          {t(`${localePath}.labels.from`)}
        </span>
        <MaskedText
          className={classes.dateBox}
          value={from}
          onChange={(e) => this.handleChange(e, 'from')}
          type="text"
          label={t(`${localePath}.labels.fromDate`)}
          mask="11.11.1111"
          placeholder=""
          mockPlaceholder=" "
          maxLength="13"
        />
        <span className={classes.labelBox}>
          {t(`${localePath}.labels.to`)}
        </span>
        <MaskedText
          className={classes.dateBox}
          value={to}
          onChange={(e) => this.handleChange(e, 'to')}
          type="text"
          label={t(`${localePath}.labels.toDate`)}
          mask="11.11.1111"
          placeholder=""
          mockPlaceholder=" "
          maxLength="13"
        />
      </div>
    );
  }
}

DateRangeForm.propTypes = {
  localePath: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  onChange: PropTypes.func,
  data: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
};

DateRangeForm.defaultProps = {
  localePath: '',
  classes: {},
  onChange: () => {},
  data: {
    from: '',
    to: '',
  },
};

DateRangeForm.contextTypes = {
  t: PropTypes.func,
};

export { DateRangeForm as DateRangeFormPlain };
export default injectSheet(styles)(DateRangeForm);
