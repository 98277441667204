import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgDerSchadenDesUnfallbeteiligten } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getDamageOfAccidentDetails } from '../../../helpers/formData/vehicleFormData';

const DamageOfAccidentDetails = ({ formData, localePath, className }, { t }) => {
  const {
    isCollisionFlow,
    isLiabilityFlow,
    isVehicleDamage,
    isPersonalInjury,
    isItemDamage,
    isOwnCar,
    personalInjuryDesc,
    itemDamageDesc,
    licenseNumber,
    carDamages,
    describeDamage,
  } = getDamageOfAccidentDetails(formData);
  if (!(isLiabilityFlow || (isCollisionFlow && !isOwnCar))) {
    return false;
  }

  return (
    <Details
      iconLeft={SvgDerSchadenDesUnfallbeteiligten}
      summary={isLiabilityFlow
        ? t(`${localePath}.damage-of-accident.title-liability`)
        : t(`${localePath}.damage-of-accident.title`)
      }
      className={className}
    >
      <p>
        {t(`${localePath}.damage-of-accident.content`, {
          contentDamage: isPersonalInjury || isItemDamage
            ? t(`${localePath}.damage-of-accident.content-damage`, {
              personDamage: isPersonalInjury ? t(`${localePath}.damage-of-accident.person-damage`) : '',
              itemDamage: isItemDamage ? t(`${localePath}.damage-of-accident.item-damage`) : '',
              collectDamage: isPersonalInjury && isItemDamage
                ? t(`${localePath}.damage-of-accident.collect-damage`) : '',
            }) : '',
          personalInjury: isPersonalInjury
            ? t(`${localePath}.damage-of-accident.personal-injury-desc`, { personalInjuryDesc }) : '',
          itemDamage: isItemDamage
            ? t(`${localePath}.damage-of-accident.item-damage-desc`, { itemDamageDesc }) : '',
        })}
        {isVehicleDamage && (
          t(`${localePath}.damage-of-accident.content-vehicle`, {
            licenseNumber,
            describeDamage: describeDamage
              ? t(`${localePath}.damage-of-accident.describe-damage`, { describeDamage }) : '',
            carDamages: carDamages || '',
          })
        )}
      </p>
    </Details>
  );
};

DamageOfAccidentDetails.propTypes = legalConsentProps.sectionProps;
DamageOfAccidentDetails.defaultProps = legalConsentProps.sectionDefaults;

DamageOfAccidentDetails.contextTypes = {
  t: PropTypes.func,
};

export default DamageOfAccidentDetails;
