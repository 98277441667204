import { types, NAV_DIRECTIONS } from '../actions/constants';
import {
  navigateToPath, getPathByStepName, navigateToBeginning, shouldRedirectToBeginning,
} from '../helpers/navigation';
import history from '../browserHistory';
import globals from '../globals';
import { redirectToBeginning } from '../actions/navigation';
import { getLastVisitedLocation, removeLastVisitedLocation } from '../services';

const getWindowParams = () => {
  const params = globals.window.location.pathname.split('/');
  const name = params.pop();
  const pathName = params.pop();
  return { pathName, name };
};

const navigation = store => next => action => {
  const result = next(action);

  switch (action.type) {
    case types.NAVIGATE_TO_PATH: {
      const params = getWindowParams();
      const storeState = store.getState();
      const { formData } = storeState;
      const summaryPath = storeState.navigation ? storeState.navigation.summaryPage : undefined;
      const previous = action.direction !== NAV_DIRECTIONS.NEXT;

      const match = {
        params,
        summaryPath,
      };

      if (summaryPath) {
        store.dispatch({
          type: types.NAVIGATION_SET_SUMMARY_PAGE,
          summaryPage: '',
        });
      }

      if (previous || (storeState.navigation && storeState.navigation.isNextActive)) {
        setTimeout(() => navigateToPath(match, history, formData, previous), action.delay);
      }
      break;
    }
    case types.NAVIGATION_UPDATE_START: {
      const { match, delay } = action;

      const storeParams = store.getState().navigation.current.params;
      const windowParams = getWindowParams();
      const lastVisitedLocation = getLastVisitedLocation();

      if (shouldRedirectToBeginning(storeParams, windowParams, lastVisitedLocation)) {
        removeLastVisitedLocation();
        store.dispatch(redirectToBeginning());
        break;
      }

      setTimeout(() => store.dispatch({
        type: types.NAVIGATION_UPDATE_END,
        match,
      }), delay);
      break;
    }
    case types.NAVIGATION_THROUGH_SECTION: {
      setTimeout(() => history.push(
        getPathByStepName(action.current, action.startOfSection.name),
      ), action.delay);
      break;
    }
    case types.REDIRECT_TO_BEGINNING: {
      const { pathName } = getWindowParams();
      navigateToBeginning(history, pathName);
      break;
    }
    default: { break; }
  }

  return result;
};


export default navigation;
