import saveClaimData from '../saveClaimService/saveClaimService';
import { claimsKeys } from '../../helpers/formData/formData';
import vehicleTransform from './vehicle';
import thirdPartyTransform from './third-party';

export const moduleNames = {
  VEHICLE: 'vehicle',
  THIRD_PARTY: 'third-party',
};

export const mapClaimData = (formData, moduleName = moduleNames.VEHICLE) => {
  if (!formData || !formData[moduleName]) {
    return null;
  }

  switch (moduleName) {
    case moduleNames.VEHICLE:
      return vehicleTransform(formData[moduleName]);
    case moduleNames.THIRD_PARTY:
      return thirdPartyTransform(formData[moduleName]);
    default:
      return vehicleTransform(formData[moduleName]);
  }
};

export const sendClaimData = async (formData, recaptchaToken, moduleName = moduleNames.VEHICLE) => {
  const claimData = mapClaimData(formData, moduleName);

  if (!claimData) {
    return null;
  }
  const {
    data,
    serviceType,
    emailData,
    brandName,
  } = claimData;

  const documents = data[claimsKeys.FILES];
  delete data[claimsKeys.FILES];

  const resource = saveClaimData();

  return resource.create({
    claims: data,
    documents,
    serviceType,
    brandName,
    emailData,
    recaptchaToken,
  });
};

export default sendClaimData;
