import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';
import { getContactDetails } from '../../../helpers/formData/vehicleFormData';
import { SvgKontaktdatenDesUnfallbeteiligten } from '../../../helpers/IconLoader';

const ContactDetails = ({ formData, localePath, className }, { t }) => {
  const {
    firstName,
    lastName,
    isCompany,
    phone,
    email,
  } = getContactDetails(formData);

  return (
    <Details
      iconLeft={SvgKontaktdatenDesUnfallbeteiligten}
      isInitiallyOpen
      summary={t(`${localePath}.contact.title`)}
      className={className}
    >
      <p>
        {t(`${localePath}.contact.content-name-${isCompany}`, { firstName, lastName })}
      </p>
      <p>
        {t(`${localePath}.contact.content-phone`, { phone })}
      </p>
      <p>
        {t(`${localePath}.contact.content-email`, { email })}
      </p>
    </Details>
  );
};

ContactDetails.propTypes = legalConsentProps.sectionProps;
ContactDetails.defaultProps = legalConsentProps.sectionDefaults;

ContactDetails.contextTypes = {
  t: PropTypes.func,
};

export default ContactDetails;
