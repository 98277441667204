import React from 'react';
import PropTypes from 'prop-types';
import MailIcon from '@eg/elements/Icons/MailIcon';
import ThumbsUpIcon from '@eg/elements/Icons/ThumbsUpIcon';
import injectJss from 'react-jss';
import styles from './ListItem.styles.jss';

const Icons = { MailIcon, ThumbsUpIcon };

const ListItem = ({
  name, localePath, classes, iconName,
}, { t }) => {
  const Icon = Icons[iconName];
  return (
    <li className={classes.item} key={name}>
      <div className={classes.content}>
        <span className={classes.iconContainer}>
          <Icon width={50} height={50} className={classes.icon} />
        </span>
        <span className={classes.text}>
          {t(`${localePath}.${name}`)}
        </span>
      </div>
    </li>
  );
};

ListItem.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  localePath: PropTypes.string,
  name: PropTypes.string,
  iconName: PropTypes.string,
};

ListItem.defaultProps = {
  classes: {},
  localePath: '',
  name: '',
  iconName: '',
};

ListItem.contextTypes = {
  t: PropTypes.func,
};

export default injectJss(styles)(ListItem);
