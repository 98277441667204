import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { legalConsentProps } from '../../../models/panels';
import { getIncidentDetails } from '../../../helpers/formData/vehicleFormData';
import { SvgPflaster } from '../../../helpers/IconLoader';

const IncidentDetails = ({ formData, localePath, className }, { t }) => {
  const {
    date,
    accidentLocation,
    accidentCountry,
    licenseNumber,
    whatHappened,
    kindOfDamage,
    thirdPartyLicenseNumber,
    isVehicleDamage,
    isGlassFlow,
    isVandalismFlow,
    isLiabilityFlow,
  } = getIncidentDetails(formData);

  return (
    <Details
      iconLeft={SvgPflaster}
      summary={t(`${localePath}.incident.title`)}
      className={className}
    >
      <p>
        { isLiabilityFlow
          ? (
            t(`${localePath}.incident.content-liability`, {
              vehicleDamage: isVehicleDamage
                ? t(`${localePath}.incident.vehicle-damage`, {
                  licenseNumber,
                  thirdPartyLicenseNumber,
                }) : '',
              date,
              accidentLocation,
              accidentCountry,
              kindOfDamage,
            })
          )
          : (
            t(`${localePath}.incident.content`, {
              date,
              accidentLocation,
              accidentCountry,
              licenseNumber,
              causeOfDamage: (isGlassFlow || isVandalismFlow) ? whatHappened : kindOfDamage,
            })
          )
        }
      </p>
    </Details>
  );
};

IncidentDetails.propTypes = legalConsentProps.sectionProps;
IncidentDetails.defaultProps = legalConsentProps.sectionDefaults;

IncidentDetails.contextTypes = {
  t: PropTypes.func,
};

export default IncidentDetails;
