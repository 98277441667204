import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';
import DatePickerInput from '../DatePickerInput';
import { convertToString } from '../DatePickerInput/convertFunctions';

const BirthDate = (props, { t }) => {
  const {
    localePath,
    getValidationProps,
    shrinkOnMobile,
  } = props;

  useEffect(() => {
    const { onChange, name, value } = getValidationProps('date');
    if (!value) {
      const date = new Date();
      date.setFullYear(2000);
      const formatted = convertToString(date);
      onChange(name)(formatted);
    }
  }, []);

  return (
    <div className="esc_grid">
      <div className="esc_grid__wrapper">
        <div className="esc_col esc_col-12 esc_col-s-4">
          <DatePickerInput
            description={t(`${localePath}.labels.dateDescription`)}
            type="text"
            placeholder={t(`${localePath}.labels.dateLabel`)}
            {...getValidationProps('date')}
            shrinkOnMobile={shrinkOnMobile}
            defaultYear={2000}
          />
        </div>
      </div>
    </div>
  );
};

BirthDate.propTypes = {
  localePath: PropTypes.string,
  hideTime: PropTypes.bool,
  getValidationProps: PropTypes.func,
  shrinkOnMobile: PropTypes.bool,
};

BirthDate.defaultProps = {
  localePath: '',
  hideTime: false,
  getValidationProps: () => {},
  shrinkOnMobile: false,
};

BirthDate.contextTypes = {
  t: PropTypes.func,
};

export { BirthDate as DateTimePlain };
export default compose(
  withMultiFieldValidation,
)(BirthDate);
