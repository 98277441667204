import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import store from '../../../store';
import { fieldUpdate } from '../../../actions/formData';
import { validate, getValidators } from '../../../helpers/validation/Validator';
import DateTime from '../../../panels/DateTime/DateTime';
import BirthDate from '../../../panels/BirthDate';
import DateRange from '../../../panels/DateRange/DateRange';
import Select from '../../../panels/Select/Select';
import ButtonSwitch from '../../../panels/ButtonSwitch';
import ButtonImageSwitch from '../../../panels/ButtonImageSwitch';
import SummaryNotice from '../../../panels/SummaryNotice/SummaryNotice';
import LongText from '../../../panels/LongText/LongText';
import Location from '../../../panels/Location/Location';
import ThankYou from '../../../panels/ThankYou/ThankYou';
import ShortText from '../../../panels/ShortText/ShortText';
import FileUpload from '../../../panels/FileUpload';
import MultipleSelect from '../../../panels/MultipleSelect/MultipleSelect';
import ErgoMultipleSelect from '../../../panels/ErgoMultipleSelect';
import MultiSelect from '../../../panels/MultiSelect';
import RepairShop from '../../../panels/RepairShop/RepairShop';
import ErgoLicenseNumber from '../../../panels/ErgoLicenseNumber/index';
import LicenseNumber from '../../../panels/LicenseNumber';
import ErgoIban from '../../../panels/ErgoIban';
import CarMap from '../../../panels/CarMap';
import SelectBool from '../../../panels/SelectBool/SelectBool';
import SummaryTodo from '../../../panels/SummaryTodo/SummaryTodo';
import PoliceReport from '../../../panels/PoliceReport';
import ContactData from '../../../panels/ContactData';
import ErgoContactDataRepairShop from '../../../panels/ErgoContactDataRepairShop';
import PostalCode from '../../../panels/PostalCode';
import LocationGeneral from '../../../panels/LocationGeneral';
import InstantRedirect from '../../../panels/InstantRedirect';
import WitnessContactData from '../../../panels/WitnessContactData';
import FormWrapper from '../../../components/FormWrapper/FormWrapper';
import FormNavigationProxy from '../../../components/FormNavigationProxy/FormNavigationProxy';
import { isMandatory } from '../../../helpers/validation/utils';
import VehicleSummaryPage from '../../../panels/VehicleSummaryPage';
import DriverContactData from '../../../panels/DriverContactData';
import PostalCodeWithStreet from '../../../panels/PostalCodeWithStreet';
import ThirdPartySummaryPage from '../../../panels/ThirdPartySummaryPage';
import InformationText from '../../../panels/InformationText';

const formComponents = {
  DateTime,
  BirthDate,
  DateRange,
  Select,
  ButtonSwitch,
  ButtonImageSwitch,
  SummaryNotice,
  LongText,
  Location,
  ThankYou,
  ShortText,
  FileUpload,
  MultipleSelect,
  ErgoMultipleSelect,
  MultiSelect,
  RepairShop,
  ErgoLicenseNumber,
  LicenseNumber,
  ErgoIban,
  CarMap,
  SelectBool,
  SummaryTodo,
  PoliceReport,
  ContactData,
  ErgoContactDataRepairShop,
  PostalCode,
  LocationGeneral,
  InstantRedirect,
  WitnessContactData,
  VehicleSummaryPage,
  DriverContactData,
  PostalCodeWithStreet,
  ThirdPartySummaryPage,
  InformationText,
};

const PanelProvider = ({
  formData, params, flowConfig, bindFormSubmit,
}) => {
  const { pathName, name } = params;
  const formikSupportedComponents = [
    'Select',
    'SelectBool',
    'ShortText',
    'FileUpload',
    'DateTime',
    'BirthDate',
    'Location',
    'ErgoLicenseNumber',
    'LicenseNumber',
    'ErgoIban',
    'ContactData',
    'ErgoContactDataRepairShop',
    'LongText',
    'PoliceReport',
    'MultipleSelect',
    'ErgoMultipleSelect',
    'MultiSelect',
    'PostalCode',
    'LocationGeneral',
    'RepairShop',
    'CarMap',
    'WitnessContactData',
    'ButtonImageSwitch',
    'VehicleSummaryPage',
    'DriverContactData',
    'PostalCodeWithStreet',
    'ThirdPartySummaryPage',
    'InformationText',
  ];

  if (flowConfig[pathName]) {
    const config = { ...flowConfig[pathName].find(e => e.name === name) };
    const updateField = e => {
      store.dispatch({
        ...fieldUpdate,
        pathName,
        fieldName: config.name,
        payload: e.target ? e.target.value : e.value,
      });
    };

    const componentType = config.component;
    const InputComponent = formComponents[config.component];
    const data = formData[pathName] && formData[pathName][name];
    delete config.component;
    const localePath = `${pathName}.${name.replace(/_/g, '.')}`;
    const validators = getValidators(config);
    config.isMandatory = isMandatory(validators);

    const inputComponent = (
      <InputComponent
        {...config}
        pathName={pathName}
        pageName={name}
        localePath={`${pathName}.${name.replace(/_/g, '.')}`}
        data={data}
        onChange={updateField}
      />
    );

    const noValidationStrategy = (
      componentType === 'ThankYou'
        ? inputComponent
        : (
          <Formik>
            <FormNavigationProxy
              isValid
              renderChildren={() => {
                bindFormSubmit(undefined);
                return inputComponent;
              }}
            />
          </Formik>
        )
    );

    const formikStrategy = (
      <FormWrapper
        {...config}
        localePath={localePath}
        onChange={updateField}
        formikConfig={{
          initialValues: {
            [name]: data,
          },
          validate: validate({
            validators,
            localePath,
            name,
          }),
        }}
      >
        {({
          errors, values, handleChange, handleBlur, handleFocus, submitForm, isValid,
        }) => {
          bindFormSubmit(submitForm);
          return (
            <InputComponent
              {...config}
              isValid={isValid}
              errors={errors}
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pathName={pathName}
              pageName={name}
              localePath={`${pathName}.${name.replace(/_/g, '.')}`}
            />
          );
        }}
      </FormWrapper>
    );

    return (
      <>
        {formikSupportedComponents.includes(componentType) // @TODO: add formik support for all panels
          ? formikStrategy
          : noValidationStrategy}
      </>
    );
  }

  return null;
};

PanelProvider.propTypes = {
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  flowConfig: PropTypes.objectOf(PropTypes.array).isRequired,
  params: PropTypes.shape({
    pathName: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  bindFormSubmit: PropTypes.func,
};

PanelProvider.defaultProps = {
  bindFormSubmit: () => {},
};

export default PanelProvider;
