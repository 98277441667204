import React from 'react';
import PropTypes from 'prop-types';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import HeadingForm from '../../layout/HeadingForm';
import DateRangeForm from './DateRangeForm/DateRangeForm';

const DateRange = ({
  localePath,
  data,
  onChange,
}, { t }) => (
  <ContentWrapper>
    <HeadingForm
      commentValue={t(`${localePath}.comment`)}
      value={t(`${localePath}.heading`)}
    />
    <DateRangeForm
      data={data}
      onChange={onChange}
      localePath={localePath}
    />
  </ContentWrapper>
);

DateRange.propTypes = {
  localePath: PropTypes.string,
  data: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

DateRange.defaultProps = {
  localePath: '',
  data: {
    from: '',
    to: '',
  },
  onChange: () => {},
};

DateRange.contextTypes = {
  t: PropTypes.func,
};

export default DateRange;
