import axios from 'axios';
import { config as conf } from '../helpers/config';
import paths from '../helpers/paths';
import { moduleNames } from './claimData/claimData';

/* eslint-disable */

const requestHandler = async (request) => {
  return request;
};

const successHandler = (response) => {
  return response;
};

/* eslint-enable */

const modalParams = [
  {
    func: 'ownCallback',
    name: 'cancel',
    variant: 'primary',
    opts: {
      callback: () => {
        const currentHref = global.location.href;
        let path = '';
        if (currentHref.includes(moduleNames.VEHICLE)) {
          path = paths.CLAIM_START.replace(':pathName', moduleNames.VEHICLE);
        } else if (currentHref.includes(moduleNames.THIRD_PARTY)) {
          path = paths.CLAIM_START.replace(':pathName', moduleNames.THIRD_PARTY);
        }

        if (path) {
          global.location = path;
        }
      },
    },
  },
];

const errorHandler = (error) => {
  global.communicationModal.showDialog('defaultCode', modalParams);
  return Promise.reject(error);
};

const AxiosInstance = axios.create({
  baseURL: conf.API_HOST,
  xsrfCookieName: 'DCIL-csrf-token',
  xsrfHeaderName: 'x-csrf-token',
});

AxiosInstance.interceptors.request.use((request) => requestHandler(request));

AxiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

export default AxiosInstance;
