import React from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import Radio from '@eg/elements/Radio';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';
import ErgoInputText from '../../components/ErgoInputText';
import styles from './styles.jss';

class ErgoIban extends React.PureComponent {
  constructor(props) {
    super();
    this.state = {
      who: props.data.who,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  resetFirstName = () => {
    const { getValidationProps } = this.props;
    const { onChange, name } = getValidationProps('firstName');
    const target = {
      name,
      value: undefined,
    };
    onChange({ target });
  };

  handleChange = e => {
    const { getValidationProps } = this.props;
    const { onChange, name } = getValidationProps('who');
    const { value } = e.target;
    const target = {
      name,
      value,
    };
    onChange({ target });
    this.resetFirstName();
    this.setState({ who: value });
  };

  render() {
    const {
      localePath, getValidationProps, options, classes,
    } = this.props;
    const COMPANY = 'company';
    const { t } = this.context;
    const { who } = this.state;
    const { name } = getValidationProps('who');
    const isCompany = who === COMPANY ? COMPANY : 'person';
    const inputs = isCompany === COMPANY ? {
      name: { size: 12 },
      iban: { size: 12 },
    } : {
      name: { size: 6 },
      firstName: { size: 6 },
      iban: { size: 12 },
    };

    return (
      <div className="esc_grid">
        <div className="esc_grid__wrapper">
          <div className={`esc_col esc_col-12 ${classes.options}`}>
            <div>
              {t(`${localePath}.labels.head`)}
            </div>
            {options.map(option => (
              <Radio
                className={classes.radioButton}
                onChange={this.handleChange}
                name={name}
                key={option}
                value={option}
                label={t(`${localePath}.labels.${option}`)}
                checked={who === option}
              />
            ))}
          </div>
          {Object.keys(inputs).map(inputName => {
            const input = inputs[inputName];
            const inputPlaceholder = t(`${localePath}.placeholders.${isCompany}.${inputName}`);
            const inputDescription = t(`${localePath}.descriptions.${isCompany}.${inputName}`);
            const validationProps = getValidationProps(inputName);

            return (
              <div key={inputName} className={`esc_col esc_col-12 esc_col-s-${input.size}`}>
                <ErgoInputText {...validationProps} placeholder={inputPlaceholder} description={inputDescription} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

ErgoIban.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  localePath: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  pathName: PropTypes.string,
  data: PropTypes.shape({
    who: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    street: PropTypes.string,
    houseNo: PropTypes.string,
    postCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    date: PropTypes.string,
  }),
  getValidationProps: PropTypes.func,
};

ErgoIban.contextTypes = {
  t: PropTypes.func,
};

ErgoIban.defaultProps = {
  classes: {},
  localePath: '',
  options: ['male', 'female', 'company'],
  pathName: '',
  data: {
    who: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
  },
  getValidationProps: () => {},
};

const mapStateToProps = ({ formData }, ownProps) => ({ data: formData[ownProps.pathName][ownProps.name] });

export { ErgoIban as ErgoIbanPlain };
export default compose(
  connect(
    mapStateToProps,
    null,
  ),
  injectSheet(styles),
  withMultiFieldValidation,
)(ErgoIban);
