import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgDokument } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getVehicleDamageDetails } from '../../../helpers/formData/vehicleFormData';

const VehicleDamageDetails = ({ formData, localePath, className }, { t }) => {
  const {
    isLiabilityFlow,
    isWholeCarTheftFlow,
    isPartsCarTheftFlow,
    isGlassFlow,
    kindOfDamage,
    carDamages,
    licenseNumber,
    describeDamage,
    stolenCarParts,
  } = getVehicleDamageDetails(formData);

  if (isLiabilityFlow || isWholeCarTheftFlow) return false;

  return (
    <Details
      iconLeft={SvgDokument}
      summary={t(`${localePath}.vehicle-damage.title`)}
      className={className}
    >
      <p>
        {!isGlassFlow && !isPartsCarTheftFlow && (
          t(`${localePath}.vehicle-damage.content`, {
            licenseNumber,
            describeDamage: describeDamage
              ? t(`${localePath}.vehicle-damage.describe-damage`, { describeDamage }) : '',
            carDamages,
          })
        )}
        {isGlassFlow && (
          t(`${localePath}.vehicle-damage.content-glass`, { kindOfDamage })
        )}
        {isPartsCarTheftFlow && (
          t(`${localePath}.vehicle-damage.content-parts-theft`, { stolenCarParts })
        )}
      </p>
    </Details>
  );
};

VehicleDamageDetails.propTypes = legalConsentProps.sectionProps;
VehicleDamageDetails.defaultProps = legalConsentProps.sectionDefaults;

VehicleDamageDetails.contextTypes = {
  t: PropTypes.func,
};

export default VehicleDamageDetails;
