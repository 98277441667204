import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@shakacode/recompose';
import injectJss from 'react-jss';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Heading from './Heading/Heading';
import ListItem from './ListItem/ListItem';
import { resetSession, sendClaimData, mapClaimData } from '../../services';
import {
  startLoading as startLoadingAction,
  stopLoading as stopLoadingAction,
} from '../../actions/loader';
import { setNavigationState as setNavigationStateAction } from '../../actions/navigation';
import { thankYouProps } from '../../models/panels';
import { claimsKeys, YES_VALUE } from '../../helpers/formData/formData';
import styles from './styles.jss';
import { phoneNumber } from '../../services/organizationService';
import handleVerifyRecaptcha from '../../helpers/recaptcha';

class ThankYou extends React.PureComponent {
  state = {
    appointmentInfo: '',
    claimNumber: '',
    isSuccess: true,
  };

  async componentDidMount() {
    const {
      setNavigationState,
      formData,
      pathName,
      stopLoading,
      startLoading,
    } = this.props;

    startLoading();
    const mappedData = mapClaimData(formData, pathName).data;
    this.setState({
      appointmentInfo: mappedData[claimsKeys.APPOINTMENT_INFO],
    });
    setNavigationState(false, false);
    const recaptchaToken = await handleVerifyRecaptcha(this.props, 'sendClaim');
    try {
      const res = await sendClaimData(formData, recaptchaToken, pathName);
      this.setState({
        claimNumber: res.data ? res.data.claimNumber : '',
      });
    } catch {
      this.setState({ isSuccess: false });
    } finally {
      stopLoading();
    }
    const { store } = this.context;
    resetSession(store);
  }

  getCorrectPathForAppointemntInfo = () => {
    const { appointmentInfo } = this.state;
    return appointmentInfo === YES_VALUE ? 'appointment-info' : 'info';
  };

  getConfigForSendClaimSuccess = () => {
    const { t } = this.context;
    const { claimNumber } = this.state;
    const { localePath, classes } = this.props;

    const headingText = t(`${localePath}.heading-success`);
    const commentText = t(`${localePath}.comment-success`, {
      claimNumber: claimNumber
        ? t(`${localePath}.claim-number`, { claimNumber }) : '',
    });
    const infoList = (
      <ul className={classes.listShort}>
        <ListItem name="email" iconName="MailIcon" localePath={localePath} />
        <ListItem name={this.getCorrectPathForAppointemntInfo()} iconName="ThumbsUpIcon" localePath={localePath} />
      </ul>
    );
    const additionalInfoText = t(`${localePath}.additional-info-success`, { phoneNumber });
    const buttonLabelText = t(`${localePath}.button-label`);
    return {
      headingText, commentText, infoList, additionalInfoText, buttonLabelText,
    };
  }

  getConfigForSendClaimFail = () => {
    const { t } = this.context;
    const { localePath } = this.props;

    const headingText = t(`${localePath}.heading-fail`);
    const commentText = t(`${localePath}.comment-fail`, { phoneNumber });
    const infoList = null;
    const buttonLabelText = t(`${localePath}.button-label`);
    return {
      headingText, commentText, infoList, buttonLabelText,
    };
  }

  render() {
    const { classes } = this.props;
    const { isSuccess } = this.state;

    const {
      headingText,
      commentText,
      infoList,
      buttonLabelText,
      additionalInfoText,
    } = isSuccess ? this.getConfigForSendClaimSuccess() : this.getConfigForSendClaimFail();

    return (
      <div className={classes.root}>
        <Heading
          buttonLabelText={buttonLabelText}
          headingText={headingText}
          commentText={commentText}
        >
          {infoList}
          <p className={classes.additionalInfoText}>{additionalInfoText}</p>
        </Heading>
      </div>
    );
  }
}

const mapStateToProps = ({ formData }) => ({ formData });

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(startLoadingAction()),
  stopLoading: () => dispatch(stopLoadingAction()),
  setNavigationState: (isNextActive, isPreviousActive) => {
    dispatch(setNavigationStateAction(isNextActive, isPreviousActive));
  },
});

ThankYou.propTypes = thankYouProps.props;

ThankYou.defaultProps = thankYouProps.defaults;

ThankYou.contextTypes = {
  t: PropTypes.func,
  store: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export { ThankYou as ThankYouPlain };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withGoogleReCaptcha,
  injectJss(styles),
)(ThankYou);
