import { mediaQuery } from '../../../styles/helpers';
import { breakpoints } from '../../../styles/constants'; // eslint-disable-line import/prefer-default-export

const styles = ({
  wrapperBox: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  labelBox: {
    width: '15%',
    alignSelf: 'flex-end',
    ...mediaQuery.max(breakpoints.desktopNav, {
      width: '25%',
      textAlign: 'right',
    }),
    ...mediaQuery.max(breakpoints.mediumMobile, {
      width: '100%',
      textAlign: 'center',
      paddingTop: '20px',
    }),
  },
  dateBox: {
    width: '30%',
    ...mediaQuery.max(breakpoints.desktopNav, {
      width: '60%',
    }),
    ...mediaQuery.max(breakpoints.mediumMobile, {
      width: '100%',
    }),
  },
});

export default styles;
