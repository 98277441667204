import PropTypes from 'prop-types';
import ProfileIcon from '@eg/elements/Icons/ProfileIcon';
import HouseIcon from '@eg/elements/Icons/HouseIcon';
import {
  SvgCar,
  SvgMoebel2,
  SvgHundHundehalter,
} from '../../helpers/IconLoader';

export const optionTypes = {
  PERSONAL_INJURY: 'personal-injury',
  VEHICLE_DAMAGE: 'vehicle-damage',
  ITEM_DAMAGE: 'item-damage',
  FURNITURE_DAMAGE: 'furniture-damage',
  ANIMAL_INJURY: 'animal-injury',
};

export const iconList = {
  [optionTypes.PERSONAL_INJURY]: ProfileIcon,
  [optionTypes.VEHICLE_DAMAGE]: SvgCar,
  [optionTypes.ITEM_DAMAGE]: HouseIcon,
  [optionTypes.FURNITURE_DAMAGE]: SvgMoebel2,
  [optionTypes.ANIMAL_INJURY]: SvgHundHundehalter,
};

export const MultipleSelectResource = PropTypes.shape({
  key: PropTypes.oneOf(Object.values(optionTypes)),
  include: PropTypes.arrayOf(PropTypes.string),
});

export const props = {
  classes: PropTypes.objectOf(PropTypes.string),
  options: PropTypes.arrayOf(MultipleSelectResource),
  localePath: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.oneOf(Object.values(optionTypes))),
  pathName: PropTypes.string,
  updateField: PropTypes.func,
  getValidationProps: PropTypes.func,
};

export const defaults = {
  classes: {},
  options: [],
  localePath: '',
  data: [],
  pathName: '',
  updateField: () => {},
  getValidationProps: () => {},
};

export default {
  optionTypes,
  iconList,
  props,
  defaults,
  MultipleSelectResource,
};
