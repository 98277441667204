import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import flowConfig from '../../flow';
import { resetSession } from '../../services';
import paths from '../../helpers/paths';

class ClaimStart extends React.PureComponent {
  componentDidMount() {
    const { match: { params } } = this.props;

    if (params && params.source) {
      const { store } = this.context;
      resetSession(store);
    }
  }

  isModuleAvailable = () => {
    const { match: { params } } = this.props;
    return params && params.pathName && flowConfig[params.pathName];
  }

  getModuleUrl = (pathName) => `/schadenmeldung/claim/${pathName}/${flowConfig[pathName][0].name}`;

  render() {
    const { match } = this.props;
    return this.isModuleAvailable() ? (
      <Redirect to={this.getModuleUrl(match.params.pathName)} />
    ) : (
      <Redirect to={paths.HOME} />
    );
  }
}

ClaimStart.propTypes = {
  match: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

ClaimStart.defaultProps = {
  match: {},
};

ClaimStart.contextTypes = {
  store: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};


export default ClaimStart;
