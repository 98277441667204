import {
  POLICYHOLDER,
  PARTY,
  getDateValueProxy,
  getTimeValueProxy,
  getYesNoValue,
  getOneFromMultiProxy,
  getUploadedFilesProxy,
  getLicenseNumber,
  getSalutationValue,
  getFirstNameValue,
  getLastNameValue,
  getPhoneValue,
  stripSpacesFromIban,
  getCarMapValuesProxy,
  getCountryCode,
  getUploadValues,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import {
  userFlows, whoFills, accidentCause, accidentGuilt,
} from '../../../helpers/formData/vehicleFormData';
import vehicleFlows from '../../../models/dataMappings/vehicleFlows';

const mappingIdentificationDay = {
  date: [claimsKeys.DATE, getDateValueProxy],
  time: [claimsKeys.TIME, getTimeValueProxy],
};

const mappingIdentificationLicenseNumber = [claimsKeys.owner.LICENSE_NUMBER, getLicenseNumber];

const mappingIdentificationContact = {
  who: [claimsKeys.owner.SALUTATION, getSalutationValue],
  firstName: [claimsKeys.owner.FIRST_NAME, getFirstNameValue],
  lastName: [claimsKeys.owner.LAST_NAME, getLastNameValue],
  phoneNumber: [claimsKeys.owner.PHONE, getPhoneValue],
  email: claimsKeys.owner.EMAIL,
};

const mappingAccidentDetailsCause = [
  claimsKeys.accident.CAUSE,
  getOneFromMultiProxy({
    'drive-up': accidentCause.DRIVE_UP,
    parking: accidentCause.PARKING,
    disregard: accidentCause.DISREGARD,
    bending: accidentCause.BENDING,
    'reversing-turning': accidentCause.REVERSING_TURNING,
    overtaking: accidentCause.OVERTAKING,
    'lane-change': accidentCause.LANE_CHANGE,
    different: accidentCause.DIFFERENT,
  }),
];

const mappingAccidentDetailsGuilt = [
  claimsKeys.accident.GUILT,
  getOneFromMultiProxy({
    me: accidentGuilt.ME,
    opponent: accidentGuilt.OPPONENT,
    'opponent-and-me': accidentGuilt.OPPONENT_AND_ME,
  }),
];

const mappingAccidentDetailsDescription = claimsKeys.accident.DESCRIPTION;

const mappingAccidentDetailsLocation = {
  location: claimsKeys.accident.LOCATION,
  country: [claimsKeys.accident.COUNTRY, getCountryCode],
};

const mappingPoliceReportIsReported = [claimsKeys.police.IS_REPORTED, getYesNoValue];

const mappingPoliceReportDetails = {
  department: claimsKeys.police.DEPARTMENT,
  caseNumber: claimsKeys.police.CASE_NUMBER,
};

const mappingDriverIsDriver = [claimsKeys.IS_DRIVER, getYesNoValue];

const mappingDriverNotPolicyholder = {
  who: [claimsKeys.driver.SALUTATION, getSalutationValue],
  firstName: [claimsKeys.driver.FIRST_NAME, getFirstNameValue],
  lastName: [claimsKeys.driver.LAST_NAME, getLastNameValue],
  street: claimsKeys.driver.STREET,
  houseNo: claimsKeys.driver.HOUSE_NO,
  postCode: claimsKeys.driver.POST_CODE,
  city: claimsKeys.driver.CITY,
  country: [claimsKeys.driver.COUNTRY, getCountryCode],
  date: [claimsKeys.driver.BIRTH_DATE, getDateValueProxy],
};

const mappingDriverPolicyholderBirthDate = {
  date: [claimsKeys.owner.BIRTH_DATE, getDateValueProxy],
};

const mappingAnyWitnesses = [claimsKeys.ANY_WITNESS, getYesNoValue];

const mappingHowManyWitnesses = claimsKeys.HOW_MANY_WITNESSES;

const mappingFirstWitness = {
  who: [claimsKeys.firstWitness.SALUTATION, getSalutationValue],
  firstName: [claimsKeys.firstWitness.FIRST_NAME, getFirstNameValue],
  lastName: [claimsKeys.firstWitness.LAST_NAME, getLastNameValue],
  phoneNumber: [claimsKeys.firstWitness.PHONE, getPhoneValue],
};

const mappingSecondWitness = {
  who: [claimsKeys.secondWitness.SALUTATION, getSalutationValue],
  firstName: [claimsKeys.secondWitness.FIRST_NAME, getFirstNameValue],
  lastName: [claimsKeys.secondWitness.LAST_NAME, getLastNameValue],
  phoneNumber: [claimsKeys.secondWitness.PHONE, getPhoneValue],
};

const mappingThirdWitness = {
  who: [claimsKeys.thirdWitness.SALUTATION, getSalutationValue],
  firstName: [claimsKeys.thirdWitness.FIRST_NAME, getFirstNameValue],
  lastName: [claimsKeys.thirdWitness.LAST_NAME, getLastNameValue],
  phoneNumber: [claimsKeys.thirdWitness.PHONE, getPhoneValue],
};

const mappingPolicyholderCarMap = {
  describeDamage: claimsKeys.owner.DESCRIBE_DAMAGE,
  carMap: [claimsKeys.owner.CAR_MAP, getCarMapValuesProxy],
};

const mappingPolicyholderIsPrivateCar = [claimsKeys.IS_PRIVATE_CAR, getYesNoValue];

const mappingPolicyholderLeasedCar = [claimsKeys.LEASED_CAR, getYesNoValue];

const mappingPolicyholderIsRepaired = [claimsKeys.IS_REPAIRED, getYesNoValue];

// IsRepaired NO
const mappingPolicyholderWantToRepair = [claimsKeys.WANT_TO_REPAIR, getYesNoValue];

const mappingPolicyholderPostalCode = {
  street: claimsKeys.workshop.LOCATION,
  postCode: claimsKeys.workshop.POST_CODE,
};

const mappingPolicyholderAppointmentInfo = claimsKeys.APPOINTMENT_INFO;

const mappingPolicyholderIsPremiumAccount = [claimsKeys.IS_PREMIUM_ACCOUNT, getYesNoValue];

const mappingPolicyholderIban = {
  who: [claimsKeys.accountHolder.SALUTATION, getSalutationValue],
  name: claimsKeys.accountHolder.NAME,
  firstName: claimsKeys.accountHolder.FIRST_NAME,
  iban: [claimsKeys.accountHolder.IBAN_NUMBER, stripSpacesFromIban],
};
// IsRepaired YES
const mappingPolicyholderHasInvoices = [claimsKeys.HAS_INVOICE, getYesNoValue];

const mappingPolicyholderWorkshopContact = {
  name: claimsKeys.workshop.NAME,
  phoneNumber: [claimsKeys.workshop.PHONE, getPhoneValue],
  city: claimsKeys.workshop.CITY,
};

const mappingUploadWhatDocuments = [claimsKeys.TYPE_FILES, getUploadValues];

export default {
  'who-fills': [
    claimsKeys.WHO_FILLS,
    getOneFromMultiProxy({
      [POLICYHOLDER]: whoFills.POLICYHOLDER,
      [PARTY]: whoFills.PARTY,
    }),
  ],
  'what-happened': [
    claimsKeys.WHAT_HAPPENED,
    getOneFromMultiProxy({
      [vehicleFlows.ANIMAL]: userFlows.ANIMAL,
      [vehicleFlows.COLLISION]: userFlows.COLLISION,
      [vehicleFlows.FIRE]: userFlows.FIRE_OR_EXPLOSION,
      [vehicleFlows.GLASS]: userFlows.GLASS,
      [vehicleFlows.THEFT]: userFlows.THEFT,
      [vehicleFlows.WEATHER]: userFlows.WEATHER,
      [vehicleFlows.VANDALISM]: userFlows.VANDALISM,
    }),
  ],
  // Identification Day
  'animal-damage_identification_day': mappingIdentificationDay,
  vandalism_identification_day: mappingIdentificationDay,
  collision_identification_day: mappingIdentificationDay,
  'damage-caused-by-weather_identification_day': mappingIdentificationDay,
  'glass-damage_identification_day': mappingIdentificationDay,
  liability_identification_day: mappingIdentificationDay,
  'fire-or-explosion_identification_day': mappingIdentificationDay,
  'theft_parts-identification_day': mappingIdentificationDay,
  theft_identification_day: mappingIdentificationDay,
  // Identification License Number
  'animal-damage_identification_license-number': mappingIdentificationLicenseNumber,
  'vandalism_identification_license-number': mappingIdentificationLicenseNumber,
  'collision_identification_license-number': mappingIdentificationLicenseNumber,
  'damage-caused-by-weather_identification_license-number': mappingIdentificationLicenseNumber,
  'glass-damage_identification_license-number': mappingIdentificationLicenseNumber,
  'liability_identification_license-number': mappingIdentificationLicenseNumber,
  'fire-or-explosion_identification_license-number': mappingIdentificationLicenseNumber,
  'theft_parts-identification_license-number': mappingIdentificationLicenseNumber,
  'theft_identification_license-number': mappingIdentificationLicenseNumber,
  // Identification Contact
  'animal-damage_identification_contact': mappingIdentificationContact,
  vandalism_identification_contact: mappingIdentificationContact,
  collision_identification_contact: mappingIdentificationContact,
  'damage-caused-by-weather_identification_contact': mappingIdentificationContact,
  'glass-damage_identification_contact': mappingIdentificationContact,
  'fire-or-explosion_identification_contact': mappingIdentificationContact,
  'theft_parts-identification_contact': mappingIdentificationContact,
  theft_identification_contact: mappingIdentificationContact,
  // Accident Cause
  'collision_accident-details_cause': mappingAccidentDetailsCause,
  'liability_accident-details_cause': mappingAccidentDetailsCause,
  // Accident Guilt
  'collision_accident-details_guilt': mappingAccidentDetailsGuilt,
  'liability_accident-details_guilt': mappingAccidentDetailsGuilt,
  // Accident Description
  'collision_accident-details_description': mappingAccidentDetailsDescription,
  'liability_accident-details_description': mappingAccidentDetailsDescription,
  // Accident Location
  'animal-damage_accident-details_location': mappingAccidentDetailsLocation,
  'vandalism_accident-details_location': mappingAccidentDetailsLocation,
  'collision_accident-details_location': mappingAccidentDetailsLocation,
  'damage-caused-by-weather_accident-details_location': mappingAccidentDetailsLocation,
  'glass-damage_accident-details_location': mappingAccidentDetailsLocation,
  'liability_accident-details_location': mappingAccidentDetailsLocation,
  'fire-or-explosion_accident-details_location': mappingAccidentDetailsLocation,
  'theft_accident-details_location': mappingAccidentDetailsLocation,
  // Police IsReported
  'animal-damage_police-with-no-report_is-reported': mappingPoliceReportIsReported,
  'collision_police-with-no-report_is-reported': mappingPoliceReportIsReported,
  'liability_police-with-no-report_is-reported': mappingPoliceReportIsReported,
  'fire-or-explosion_police-with-no-report_is-reported': mappingPoliceReportIsReported,
  'vandalism_police-with-report_is-reported': mappingPoliceReportIsReported,
  'fire-or-explosion_police-with-report_is-reported': mappingPoliceReportIsReported,
  'theft_police-with-report_is-reported': mappingPoliceReportIsReported,
  // Police ReportDetails
  'animal-damage_police-with-no-report_report-details': mappingPoliceReportDetails,
  'collision_police-with-no-report_report-details': mappingPoliceReportDetails,
  'liability_police-with-no-report_report-details': mappingPoliceReportDetails,
  'fire-or-explosion_police-with-no-report_report-details': mappingPoliceReportDetails,
  'vandalism_police-with-report_report-details': mappingPoliceReportDetails,
  'fire-or-explosion_police-with-report_report-details': mappingPoliceReportDetails,
  'theft_police-with-report_report-details': mappingPoliceReportDetails,
  // Driver
  'animal-damage_driver_is-policyholder-driver': mappingDriverIsDriver,
  'collision_driver_is-policyholder-driver': mappingDriverIsDriver,
  'animal-damage_driver_not-policyholder': mappingDriverNotPolicyholder,
  'collision_driver_not-policyholder': mappingDriverNotPolicyholder,
  'animal-damage_driver_policyholder-birth-date': mappingDriverPolicyholderBirthDate,
  'collision_driver_policyholder-birth-date': mappingDriverPolicyholderBirthDate,
  // Collision Witnesses
  'collision_are-there-any-witnesses_witnesses': mappingAnyWitnesses,
  'collision_are-there-any-witnesses_how-many-witnesses': mappingHowManyWitnesses,
  'collision_are-there-any-witnesses_only_witness': mappingFirstWitness,
  'collision_are-there-any-witnesses_first_witness': mappingFirstWitness,
  'collision_are-there-any-witnesses_second_witness': mappingSecondWitness,
  'collision_are-there-any-witnesses_third_witness': mappingThirdWitness,
  // Liability Witnesses
  'liability_are-there-any-witnesses_witnesses': mappingAnyWitnesses,
  'liability_are-there-any-witnesses_how-many-witnesses': mappingHowManyWitnesses,
  'liability_are-there-any-witnesses_only_witness': mappingFirstWitness,
  'liability_are-there-any-witnesses_first_witness': mappingFirstWitness,
  'liability_are-there-any-witnesses_second_witness': mappingSecondWitness,
  'liability_are-there-any-witnesses_third_witness': mappingThirdWitness,
  // Policyholder Car Map
  'theft_policyholder_car-map': mappingPolicyholderCarMap,
  'animal-damage_policyholder_car-map': mappingPolicyholderCarMap,
  'fire-or-explosion_policyholder_car-map': mappingPolicyholderCarMap,
  'vandalism_policyholder_car-map': mappingPolicyholderCarMap,
  'damage-caused-by-weather_policyholder_car-map': mappingPolicyholderCarMap,
  'collision_policyholder_car-map': mappingPolicyholderCarMap,
  'glass-damage_policyholder_car-map': mappingPolicyholderCarMap,
  // Policyholder IsPrivateCar
  'theft_policyholder_is-private-car': mappingPolicyholderIsPrivateCar,
  'animal-damage_policyholder_is-private-car': mappingPolicyholderIsPrivateCar,
  'fire-or-explosion_policyholder_is-private-car': mappingPolicyholderIsPrivateCar,
  'vandalism_policyholder_is-private-car': mappingPolicyholderIsPrivateCar,
  'damage-caused-by-weather_policyholder_is-private-car': mappingPolicyholderIsPrivateCar,
  'collision_policyholder_is-private-car': mappingPolicyholderIsPrivateCar,
  'glass-damage_policyholder_is-private-car': mappingPolicyholderIsPrivateCar,
  // Policyholder LeasedCar
  'theft_policyholder_leased-car': mappingPolicyholderLeasedCar,
  'animal-damage_policyholder_leased-car': mappingPolicyholderLeasedCar,
  'fire-or-explosion_policyholder_leased-car': mappingPolicyholderLeasedCar,
  'vandalism_policyholder_leased-car': mappingPolicyholderLeasedCar,
  'damage-caused-by-weather_policyholder_leased-car': mappingPolicyholderLeasedCar,
  'collision_policyholder_leased-car': mappingPolicyholderLeasedCar,
  // Policyholder IsRepaired
  'theft_policyholder_is-repaired': mappingPolicyholderIsRepaired,
  'animal-damage_policyholder_is-repaired': mappingPolicyholderIsRepaired,
  'fire-or-explosion_policyholder_is-repaired': mappingPolicyholderIsRepaired,
  'vandalism_policyholder_is-repaired': mappingPolicyholderIsRepaired,
  'damage-caused-by-weather_policyholder_is-repaired': mappingPolicyholderIsRepaired,
  'collision_policyholder_is-repaired': mappingPolicyholderIsRepaired,
  'glass-damage_policyholder_is-repaired': mappingPolicyholderIsRepaired,
  // Policyholder WantToRepair
  'theft_policyholder_want-to-repair': mappingPolicyholderWantToRepair,
  'animal-damage_policyholder_want-to-repair': mappingPolicyholderWantToRepair,
  'fire-or-explosion_policyholder_want-to-repair': mappingPolicyholderWantToRepair,
  'vandalism_policyholder_want-to-repair': mappingPolicyholderWantToRepair,
  'damage-caused-by-weather_policyholder_want-to-repair': mappingPolicyholderWantToRepair,
  'collision_policyholder_want-to-repair': mappingPolicyholderWantToRepair,
  // Policyholder PostalCode
  'theft_policyholder_postal-code': mappingPolicyholderPostalCode,
  'animal-damage_policyholder_postal-code': mappingPolicyholderPostalCode,
  'fire-or-explosion_policyholder_postal-code': mappingPolicyholderPostalCode,
  'vandalism_policyholder_postal-code': mappingPolicyholderPostalCode,
  'damage-caused-by-weather_policyholder_postal-code': mappingPolicyholderPostalCode,
  'collision_policyholder_postal-code': mappingPolicyholderPostalCode,
  'glass-damage_policyholder_postal-code': mappingPolicyholderPostalCode,
  // Policyhoder AppointmentInfo
  'theft_policyholder_appointment-info': mappingPolicyholderAppointmentInfo,
  'animal-damage_policyholder_appointment-info': mappingPolicyholderAppointmentInfo,
  'fire-or-explosion_policyholder_appointment-info': mappingPolicyholderAppointmentInfo,
  'vandalism_policyholder_appointment-info': mappingPolicyholderAppointmentInfo,
  'damage-caused-by-weather_policyholder_appointment-info': mappingPolicyholderAppointmentInfo,
  'collision_policyholder_appointment-info': mappingPolicyholderAppointmentInfo,
  'glass-damage_policyholder_appointment-info': mappingPolicyholderAppointmentInfo,
  // Policyholder IsPremiumAccount
  'theft_policyholder_is-premium-account': mappingPolicyholderIsPremiumAccount,
  'theft_policyholder_repaired_is-premium-account': mappingPolicyholderIsPremiumAccount,
  'animal-damage_policyholder_is-premium-account': mappingPolicyholderIsPremiumAccount,
  'animal-damage_policyholder_repaired_is-premium-account': mappingPolicyholderIsPremiumAccount,
  'fire-or-explosion_policyholder_is-premium-account': mappingPolicyholderIsPremiumAccount,
  'fire-or-explosion_policyholder_repaired_is-premium-account': mappingPolicyholderIsPremiumAccount,
  'vandalism_policyholder_is-premium-account': mappingPolicyholderIsPremiumAccount,
  'vandalism_policyholder_repaired_is-premium-account': mappingPolicyholderIsPremiumAccount,
  'damage-caused-by-weather_policyholder_is-premium-account': mappingPolicyholderIsPremiumAccount,
  'damage-caused-by-weather_policyholder_repaired_is-premium-account': mappingPolicyholderIsPremiumAccount,
  'collision_policyholder_is-premium-account': mappingPolicyholderIsPremiumAccount,
  'collision_policyholder_repaired_is-premium-account': mappingPolicyholderIsPremiumAccount,
  'glass-damage_policyholder_repaired_is-premium-account': mappingPolicyholderIsPremiumAccount,
  // Policyholder Iban
  theft_policyholder_iban: mappingPolicyholderIban,
  theft_policyholder_repaired_iban: mappingPolicyholderIban,
  'animal-damage_policyholder_iban': mappingPolicyholderIban,
  'animal-damage_policyholder_repaired_iban': mappingPolicyholderIban,
  'fire-or-explosion_policyholder_iban': mappingPolicyholderIban,
  'fire-or-explosion_policyholder_repaired_iban': mappingPolicyholderIban,
  vandalism_policyholder_iban: mappingPolicyholderIban,
  vandalism_policyholder_repaired_iban: mappingPolicyholderIban,
  'damage-caused-by-weather_policyholder_iban': mappingPolicyholderIban,
  'damage-caused-by-weather_policyholder_repaired_iban': mappingPolicyholderIban,
  collision_policyholder_iban: mappingPolicyholderIban,
  collision_policyholder_repaired_iban: mappingPolicyholderIban,
  'glass-damage_policyholder_repaired_iban': mappingPolicyholderIban,
  // Policyholder HasInvoice
  'theft_policyholder_repaired_has-invoices': mappingPolicyholderHasInvoices,
  'animal-damage_policyholder_repaired_has-invoices': mappingPolicyholderHasInvoices,
  'fire-or-explosion_policyholder_repaired_has-invoices': mappingPolicyholderHasInvoices,
  'vandalism_policyholder_repaired_has-invoices': mappingPolicyholderHasInvoices,
  'damage-caused-by-weather_policyholder_repaired_has-invoices': mappingPolicyholderHasInvoices,
  'collision_policyholder_repaired_has-invoices': mappingPolicyholderHasInvoices,
  'glass-damage_policyholder_repaired_has-invoices': mappingPolicyholderHasInvoices,
  // Policyholder WorkshopContact
  'theft_policyholder_repaired_workshop-contact': mappingPolicyholderWorkshopContact,
  'animal-damage_policyholder_repaired_workshop-contact': mappingPolicyholderWorkshopContact,
  'fire-or-explosion_policyholder_repaired_workshop-contact': mappingPolicyholderWorkshopContact,
  'vandalism_policyholder_repaired_workshop-contact': mappingPolicyholderWorkshopContact,
  'damage-caused-by-weather_policyholder_repaired_workshop-contact': mappingPolicyholderWorkshopContact,
  'collision_policyholder_repaired_workshop-contact': mappingPolicyholderWorkshopContact,
  'glass-damage_policyholder_repaired_workshop-contact': mappingPolicyholderWorkshopContact,
  // Upload
  'document-upload_has-files': [claimsKeys.HAS_FILES, getYesNoValue],
  'document-upload_what-documents': mappingUploadWhatDocuments,
  'document-upload_files': [claimsKeys.FILES, getUploadedFilesProxy],
};
