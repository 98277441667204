import './fonts.css';
import * as ErgoBreakpoints from '@eg/elements/styles/breakpoints.json';

import * as ErgoColors from '@eg/elements/styles/ergo-one/colors.json';
import { objectsForTheme } from '../helpers/format';

export const breakpoints = objectsForTheme(ErgoBreakpoints);

const ergoColors = objectsForTheme(ErgoColors);
const expandedColorSet = {
  expandedIconBlack: '#1d1d1d',
  red: '#E02020',
};

const emsClaimsBrandColor = '#035496';

const customizedErgoColors = {
  ...ergoColors,
  ...{
    themePrimary: emsClaimsBrandColor,
    themeSecondary: emsClaimsBrandColor,
    themeTertiary: emsClaimsBrandColor,
    themeBrandColorLighter: emsClaimsBrandColor,
    themeBrandColorLight: emsClaimsBrandColor,
    themeBrandColor: emsClaimsBrandColor,
    themeBrandColorDark: emsClaimsBrandColor,
    themeBrandColorDarker: emsClaimsBrandColor,
    themeAdditional1: emsClaimsBrandColor,
  },
};

export const colors = { ...customizedErgoColors, ...expandedColorSet };

// console.log('ErgoColors: ', colors);
// console.log('ErgoBreakpoints: ', breakpoints);

// TODO: remove all styles
// colors:
export const primaryBasicColor = '#ffffff';
export const secondaryBasicColor = '#f7f7f7';
export const thirdBasicColor = '#e9e4de';
export const forthBasicColor = '#6b625a';

export const activeColor = emsClaimsBrandColor;
export const primaryBasicColorDimmed = 'rgba(255, 255, 255, 0.3)';

export const primaryFontColor = '#6a625a';
export const headingMainColor = 'rgba(0, 0, 0, .8)';
export const headingCommentColor = 'rgba(0, 0, 0, .65)';
export const lightFiledMainColor = 'rgba(0, 0, 0, .65)';
export const brightFieldActiveColor = 'rgb(255, 255, 255)';
export const secondaryFontColor = '#000000';
export const thirdFontColor = primaryBasicColor;
export const forthFontColor = '#ffffff';
export const fifthFontColor = '#88817a';
export const sixsthFontColor = '#4a4a4a';
export const seventhFontColor = emsClaimsBrandColor;
export const eightFontColor = '#6c625a';
export const primaryAccentColor = '#000000';
export const secondaryAccentColor = seventhFontColor;
export const thirdAccentColor = primaryFontColor;
export const validationErrorFontColor = emsClaimsBrandColor;

export const carAreaColor = emsClaimsBrandColor;
export const blurColor = 'hsla(33,20%,89%,.33)';
export const carAreaColorHover = emsClaimsBrandColor;

// colors buttons:
export const disabledButtonColor = '#F0EEEB';
export const disabledButtonFontColor = '#B8B4B1';
export const primaryButtonColor = thirdBasicColor;
export const secondaryButtonColor = secondaryBasicColor;
export const thirdButtonColor = thirdAccentColor;

export const primaryHoverButtonColor = primaryBasicColor;
export const secondaryHoverButtonColor = primaryButtonColor;

// font opacity:
export const inActiveOpacity = '0.7';
export const grayedOutOpacity = '0.3';

// font sizes:
export const progressBarFontSize = '12px';
export const progressBarMobileFontSize = '10px';
export const buttonFontSize = '12px';
export const labelFontSize = '14px';
export const inputFontSize = '24px';
export const labelFontBig = '32px';
export const labelFontSmall = '12px';
export const labelFontExtraSmall = '10px';
export const labelFontBigGridSmall = '20px';
export const toggleFontSize = '24px';
export const toggleComplementaryFontSize = '16px';
export const signBoxFontSize = '16px';
export const subTextFontSize = '14px';
export const mainPanelButtonFontSize = '20px';
export const mainPanelButtonFontSizeSmall = '14px';
export const validationFontSize = '16px';
export const validationMobileFontSize = '12px';

// font families:
export const boldFont = 'FSMeWeb-Bold, sans-serif';
export const mainFont = 'FSMeWeb-Regular, sans-serif';
export const lightFont = 'FSMeWeb-Light, sans-serif';

// grid:
export const gridDesktopNav = '940px';
export const gridBigMobile = '720px';
export const gridMediumMobile = '540px';
export const gridSmallScreen = '320px';

// margins:
export const elementInTheMiddle = '0 0 0 calc(50% - 20px)';
export const elementBasicMargin = '12px';
export const elementLargeMargin = '20px';
export const elementXLargeMargin = '35px';
export const inputMargin = '0px';

// paddings:
export const buttonPadding = '16px';
export const inputPadding = '6px 20px 6px 0';
export const inputSmallPadding = '6px 6px';
export const footerHeight = '96px';
export const buttonBoxPaddingTop = '26px';

// radius:
export const basicBorderRadius = '4px';
export const carMapAreaRadius = '8px';

// shadow:
export const boxShadow = '1px 1px 12px 0 rgba(106, 98, 90, .5)';

// sizes:
export const signBoxPadding = '12px';
export const signBoxSize = '30px';
export const signBoxSpace = '16px';
export const iconSize = '16px';
export const borderPanelSize = '6px';
export const borderPanelSizeSmall = '4px';

export const panelButtonHeight = '180px';
export const panelButonIconSize = '50px';
export const panelButtonStripHeight = '5px';

export const panelNavigationActiveBorder = `4px solid ${emsClaimsBrandColor}`;

export const progressBarHeight = '6';
export const percentageHeight = '8';

export const topBarHeight = '183px';

// transitions:
export const hoverTransition = 'all .2s ease-in';
export const panelAnimation = 'transform 300ms ease, border-width 300ms ease';

// aniamtions:
export const animationDurationBasic = '1s';

// TODO refactoring
export const buttonMargin = ({ buttonComponent, buttonInline }) => {
  if (buttonComponent === 'true') return elementInTheMiddle;
  if (buttonInline === 'true') return elementBasicMargin;
  return elementBasicMargin;
};

export const easeInCubic = 'cubic-bezier(0.55, 0.055, 0.675, 0.19)';
