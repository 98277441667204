import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgPolizeiUndZeugen } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getPoliceDetails, getWitnessesDetails } from '../../../helpers/formData/vehicleFormData';

const PoliceDetails = ({ formData, localePath, className }, { t }) => {
  const {
    isPoliceReported,
    department,
    caseNumber,
    isAnyWitness,
  } = getPoliceDetails(formData);
  let witnesses = [];
  if (isAnyWitness) {
    witnesses = getWitnessesDetails(formData);
  }
  if (!isPoliceReported && !isAnyWitness) return false;

  return (
    <Details
      iconLeft={SvgPolizeiUndZeugen}
      summary={t(`${localePath}.police.title`)}
      className={className}
    >
      {isPoliceReported && (
        <p>
          {t(`${localePath}.police.content-details`, {
            department,
            caseNumber: caseNumber ? t(`${localePath}.police.content-case-number`, { caseNumber }) : '',
          })}
        </p>
      )}
      {isAnyWitness && (
        <>
          <p>{t(`${localePath}.police.content-witnesses`)}</p>
          {witnesses.map(witness => (
            <p key={witness.firstName}>
              {t(`${localePath}.police.content-witness`, {
                firstName: witness.firstName,
                lastName: witness.lastName,
                phone: witness.phone ? t(`${localePath}.police.witness-phone`, { phone: witness.phone }) : '',
              })}
            </p>
          ))}
        </>
      )}
    </Details>
  );
};

PoliceDetails.propTypes = legalConsentProps.sectionProps;
PoliceDetails.defaultProps = legalConsentProps.sectionDefaults;

PoliceDetails.contextTypes = {
  t: PropTypes.func,
};

export default PoliceDetails;
