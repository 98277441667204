import React, { Component } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import styles from './styles.jss';
import List from './List';
import { getTranslationArray } from '../../helpers/polyglot';
import Footer from '../../layout/Footer/Footer';
import TopBar from '../../layout/TopBar/TopBar';

class Impressum extends Component {
  state ={
    impressumLocales: [],
  }

  componentDidMount() {
    const impressumTranslationArray = getTranslationArray('impressum');
    if (impressumTranslationArray && impressumTranslationArray.length) {
      const [impressumLocales] = impressumTranslationArray;
      this.setState({ impressumLocales });
    }
  }

  render() {
    const { classes } = this.props;
    const { impressumLocales } = this.state;

    return (
      <div className={classes.impressumPage}>
        <TopBar />
        <div className={classes.container}>

          <h2 className={classes.heading}>{impressumLocales.firstSectionHeading}</h2>
          <List items={impressumLocales.firstSectionList} classes={classes} />
          <List items={impressumLocales.firstSectionSubList} classes={classes} />

          <p className={classes.contentItem}>{impressumLocales.secondSectionHeading}</p>
          <div className={classes.horizontalInnerContainer}>
            <List items={impressumLocales.secondSectionListLeft} classes={classes} />
            <List items={impressumLocales.secondSectionListRight} classes={classes} />
          </div>

          <List items={impressumLocales.thirdSectionList} classes={classes} />
        </div>
        <Footer />
      </div>
    );
  }
}

Impressum.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

Impressum.defaultProps = {
  classes: {},
};

export { Impressum as ImpressumPlain };
export default injectSheet(styles)(Impressum);
