import { is, pickBy } from 'ramda';
import { isEqual } from 'lodash';
import {
  getCountryNameByCode,
  getFormattedDateValue,
} from '../../services/claimData/claimData.helpers';

export const YES_VALUE = 'j';
export const NO_VALUE = 'n';
export const YES_BOOLEAN_VALUE = '1';
export const NO_BOOLEAN_VALUE = '0';

export const userTypes = {
  OWNER: 'owner',
  THIRD_PARTY: 'thirdParty',
};

export const claimsKeys = {
  WHO_FILLS: 'WhoFills',
  WHAT_HAPPENED: 'WhatHappened',
  KIND_OF_DAMAGE: 'KindOfDamage',
  DATE: 'Date',
  TIME: 'Time',
  RECAPTCHA_TOKEN: 'recaptchaToken',
  owner: {
    SALUTATION: 'Who',
    FIRST_NAME: 'FirstName',
    LAST_NAME: 'LastName',
    PHONE: 'Phone',
    EMAIL: 'Email',
    ADDRESS: 'Address',
    POST_CODE: 'PostCode',
    CITY: 'City',
    BIRTH_DATE: 'BirthDate',
    COUNTRY_CODE: 'Country',
    LICENSE_NUMBER: 'LicenseNumber',
    CAR_MAP: 'CarMap',
    DESCRIBE_DAMAGE: 'DamageDescription',
  },
  accident: {
    CAUSE: 'AccidentCause',
    GUILT: 'AccidentGuilt',
    DESCRIPTION: 'AccidentDescription',
    LOCATION: 'AccidentLocation',
    COUNTRY: 'AccidentCountry',
  },
  police: {
    FORESTER_IS_REPORTED: 'ForesterIsReported',
    IS_REPORTED: 'PoliceIsReported',
    DEPARTMENT: 'PoliceDepartment',
    CASE_NUMBER: 'PoliceCaseNumber',
  },
  IS_DRIVER: 'IsDriver',
  driver: {
    SALUTATION: 'DriverWho',
    FIRST_NAME: 'DriverFirstName',
    LAST_NAME: 'DriverLastName',
    PHONE: 'DriverPhone',
    ADDRESS: 'DriverAddress',
    STREET: 'DriverStreet',
    HOUSE_NO: 'DriverHouseNo',
    POST_CODE: 'DriverPostCode',
    CITY: 'DriverCity',
    COUNTRY: 'DriverCountry',
    BIRTH_DATE: 'DriverBirthDate',
  },
  ANY_WITNESS: 'AnyWitness',
  HOW_MANY_WITNESSES: 'HowManyWitnesses',
  firstWitness: {
    SALUTATION: 'FirstWitnessWho',
    FIRST_NAME: 'FirstWitnessFirstName',
    LAST_NAME: 'FirstWitnessLastName',
    PHONE: 'FirstWitnessPhone',
  },
  secondWitness: {
    SALUTATION: 'SecondWitnessWho',
    FIRST_NAME: 'SecondWitnessFirstName',
    LAST_NAME: 'SecondWitnessLastName',
    PHONE: 'SecondWitnessPhone',
  },
  thirdWitness: {
    SALUTATION: 'ThirdWitnessWho',
    FIRST_NAME: 'ThirdWitnessFirstName',
    LAST_NAME: 'ThirdWitnessLastName',
    PHONE: 'ThirdWitnessPhone',
  },
  thirdParty: {
    SALUTATION: 'ThirdPartyWho',
    FIRST_NAME: 'ThirdPartyFirstName',
    LAST_NAME: 'ThirdPartyLastName',
    PHONE: 'ThirdPartyPhone',
    EMAIL: 'ThirdPartyEmail',
    IS_KNOWN: 'ThirdPartyDataIsKnown',
    ADDRESS: 'ThirdPartyAddress',
    STREET: 'ThirdPartyStreet',
    HOUSE_NO: 'ThirdPartyHouseNo',
    POST_CODE: 'ThirdPartyPostCode',
    CITY: 'ThirdPartyCity',
    COUNTRY: 'ThirdPartyCountry',
    LICENSE_NUMBER: 'ThirdPartyLicenseNumber',
    PERSONAL_INJURY: 'PersonalInjury',
    PERSONAL_INJURY_DESC: 'PersonalInjuryDescription',
    ITEM_DAMAGE: 'ItemDamage',
    ITEM_DAMAGE_DESC: 'ItemDamageDescription',
    VEHICLE_DAMAGE: 'VehicleDamage',
    VEHICLE_TYPE: 'VehicleType',
    CAR_MAP: 'ThirdPartyCarMap',
    DESCRIBE_DAMAGE: 'ThirdPartyDamageDescription',
  },
  STOLEN_CAR_PARTS: 'StolenCarParts',
  COLLISION_TYPE: 'CollisionType',
  IS_PRIVATE_CAR: 'IsPrivateCar',
  LEASED_CAR: 'LeasedCar',
  IS_REPAIRED: 'IsRepaired',
  WANT_TO_REPAIR: 'WantToRepair',
  IS_IN_WORKSHOP: 'IsInWorkshop',
  APPOINTMENT_INFO: 'AppointmentInfo',
  IS_PREMIUM_ACCOUNT: 'IsPremiumAccount',
  accountHolder: {
    SALUTATION: 'AccountHolderWho',
    NAME: 'AccountHolderName',
    FIRST_NAME: 'AccountHolderFirstName',
    IBAN_NUMBER: 'IbanNumber',
  },
  HAS_INVOICE: 'HasInvoice',
  workshop: {
    NAME: 'WorkshopName',
    PHONE: 'WorkshopPhone',
    CITY: 'WorkshopCity',
    POST_CODE: 'WorkshopPostCode',
    STREET: 'WorkshopStreet',
    LOCATION: 'WorkshopLocation',
  },
  HAS_FILES: 'HasFiles',
  TYPE_FILES: 'TypeOfFiles',
  FILES: 'documents',
};

export const compareValues = (firstValue, secondValue = YES_VALUE) => isEqual(firstValue, secondValue);

export const getDate = value => (value && getFormattedDateValue(value)) || '';

export const getCountryName = code => getCountryNameByCode(code);

export const pickFormDataForStorage = source => pickBy(
  value => !(is(Object, value) && value.doNotSaveInStorage),
  source,
);
