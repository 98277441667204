import React from 'react';
import PropTypes from 'prop-types';
import Preview from '../Upload/Preview';

const UploadList = ({
  files, accept, onRemove, namespace,
}) => (
  <>
    {files.map((file, index) => (
      <div
        key={file.location}
        className="esc_col-12 esc_col-s-6 esc_col-m-4 esc_col-l-4"
      >
        <Preview
          file={file}
          accept={accept}
          onRemove={() => onRemove(file.location)}
          name={`${namespace}-file-${index}`}
        />
      </div>
    ))}
  </>
);

UploadList.propTypes = {
  onRemove: PropTypes.func,
  accept: PropTypes.string,
  namespace: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.object),
};

UploadList.defaultProps = {
  files: [],
  accept: '',
  namespace: '',
  onRemove: () => {},
};

export { UploadList as UploadListPlain };
export default UploadList;
