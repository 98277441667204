import {
  claimsKeys,
  compareValues,
  getDate,
} from './formData';
import { ORGANIZATIONS, organizationName } from '../../services/organizationService';
import { getTranslationArray } from '../polyglot';
import { getCountryNameByCode } from '../../services/claimData/claimData.helpers';

export const whoFills = {
  POLICYHOLDER: 'Ergo Policyholder',
  PARTY: 'No Ergo Policyholder',
};

export const userFlows = {
  COLLISION: 'Unfall',
  ANIMAL: 'Tiere',
  FIRE_OR_EXPLOSION: 'Brand / Explosion / Kurzschluss',
  THEFT: 'Diebstahl',
  WEATHER: 'Unwetter',
  GLASS: 'Glasbruch',
  VANDALISM: 'Vandalismus',
};

export const collisionDamages = {
  COLLISION: 'Kollision zwischen mehreren Kfz',
  PROPERTY_DAMAGE: 'Sachschaden mit Kfz',
  PERSONAL_INJURY: 'Personenschaden mit Kfz',
  PROPERTY_DAMAGE_WITHOUT_MOTOR: 'Sachschaden ohne Kfz',
  PERSONAL_INJURY_WITHOUT_MOTOR: 'Personenschaden ohne Kfz',
};

export const animalDamages = {
  WILD_ANIMALS: 'Zusammenstoß mit einem Tier',
  OTHER_ANIMALS: 'Unfall ohne Zusammenstoß mit einem Tier',
  BITE: 'Tierbiss',
};

export const weatherDamages = {
  LIGHTNING: 'Blitz',
  FLOOD: 'Hochwasser',
  AVALANCHE: 'Lawine',
  LANDSLIDE: 'Erdrutsch',
  EARTHQUAKE: 'Erdbeben',
  STORM: 'Sturm',
  HAIL: 'Hagel',
};

export const fireDamages = {
  FIRE: 'Brand',
  EXPLOSION: 'Explosion',
  SHORT_CIRCUIT: 'Kurzschluss',
};

export const theftDamages = {
  CAR: 'Totalentwendung - das gesamte Fahrzeug',
  PARTS: 'Teilentwendung - Teile des Fahrzeugs',
};

export const glassDamages = {
  FRONT_WINDOW: 'Windschutzscheibe',
  BACK_WINDOW: 'Heckscheibe',
  SIDE_WINDOW: 'Seitenscheibe',
  ROOF_GLAZING: 'Dachverglasung',
  HEADLIGHTS: 'Scheinwerfer',
  MIRROR_GLASS: 'Spiegelglas',
};

export const accidentCause = {
  DRIVE_UP: 'Auffahren',
  PARKING: 'Ein- und Ausparken',
  DISREGARD: 'Missachtung der Vorfahrt',
  BENDING: 'Abbiegen',
  REVERSING_TURNING: 'Rückwärtsfahren und Wenden',
  OVERTAKING: 'Überholvorgang',
  LANE_CHANGE: 'Spurwechsel',
  DIFFERENT: 'Sonstiges',
};

export const accidentGuilt = {
  ME: 'Ich',
  OPPONENT: 'Unfallgegner',
  OPPONENT_AND_ME: 'Unfallgegner und ich',
};

export const collisionTypes = {
  OWN_CAR: 'Eigenes Fahrzeug',
  OTHER_CAR_OBJECT_PERSON: 'Fremdes Fahrzeug, Gegenstände und Personen',
  BOTH: 'Beides (A und B)',
};

export const whatHappenedValues = {
  ACCIDENT: 'KFZ Unfall',
  PERSONAL_INJURY: 'KFZ Personenschaden',
  LIABILITY: 'KFZ Haftpflicht',
  ACCIDENT_LIABILITY: 'KFZ Unfall/Haftpflicht',
  ACCIDENT_PERSONAL_INJURY: 'KFZ Unfall/Personenschaden',
  LIABILITY_PERSONAL_INJURY: 'KFZ Haftpflicht/Personenschaden',
  ACCIDENT_LIABILITY_PERSONAL_INJURY: 'KFZ Unfall/Haftpflicht/Personenschaden',
};

export const stolenCarPartsValues = {
  antenna: 'Antenne',
  'exterior-mirrors': 'Außenspiegel',
  tires: 'Räder',
  airbag: 'Airbag(s)',
  'audio-device': 'Audiogerät(e)',
  'navigation-device': 'Navigationsgerät(e)',
  different: 'Sonstiges',
};

export const vehicleTypes = {
  CAR: 'PKW',
  TRUCK: 'LKW',
  MOTORCYCLE: 'Motorrad',
  OTHER: 'Sonstiges',
};

export const documentTypes = {
  photo: 'Fotos',
  'cost-estimation': 'Kostenvoranschlag/ Gutachten',
  invoice: 'Rechnung',
  'other-correspondence': 'Sonstige Korrespondenz',
};

const isCompany = who => {
  const companyValue = getTranslationArray('common.salutation').company;
  return compareValues(who, companyValue);
};

const isLiabilityFlow = who => compareValues(who, whoFills.PARTY);

const isGlassFlow = whatHappened => compareValues(whatHappened, userFlows.GLASS);

const isCollisionFlow = whatHappened => compareValues(whatHappened, userFlows.COLLISION);

const isVandalismFlow = whatHappened => compareValues(whatHappened, userFlows.VANDALISM);

const isTheftFlow = whatHappened => compareValues(whatHappened, userFlows.THEFT);

const isWholeCarTheftFlow = (whatHappened, kindOfDamage) => (
  isTheftFlow(whatHappened)
  && compareValues(kindOfDamage, theftDamages.CAR)
);

const isPartsCarTheftFlow = (whatHappened, kindOfDamage) => (
  isTheftFlow(whatHappened)
  && compareValues(kindOfDamage, theftDamages.PARTS)
);

export const getFirstStepData = ({
  [claimsKeys.WHO_FILLS]: whoFill,
  [claimsKeys.WHAT_HAPPENED]: whatHappened,
  [claimsKeys.KIND_OF_DAMAGE]: kindOfDamage,
  [claimsKeys.DATE]: date,
  [claimsKeys.owner.LICENSE_NUMBER]: licenseNumber,
  [claimsKeys.RECAPTCHA_TOKEN]: recaptchaToken,
}) => ({
  whoFill, whatHappened, kindOfDamage, date, licenseNumber, recaptchaToken,
});

export const getHeadingData = ({
  [claimsKeys.WHO_FILLS]: whoFillsData,
  [claimsKeys.owner.SALUTATION]: who,
  [claimsKeys.owner.FIRST_NAME]: firstName,
  [claimsKeys.owner.LAST_NAME]: lastName,
  [claimsKeys.thirdParty.SALUTATION]: tWho,
  [claimsKeys.thirdParty.FIRST_NAME]: tFirstName,
  [claimsKeys.thirdParty.LAST_NAME]: tLastName,
}) => {
  const liabilityFlow = isLiabilityFlow(whoFillsData);
  if (liabilityFlow) {
    return isCompany(tWho) ? tFirstName : `${tFirstName} ${tLastName}`;
  }
  return isCompany(who) ? firstName : `${firstName} ${lastName}`;
};

export const getContactDetails = ({
  [claimsKeys.WHO_FILLS]: whoFillsData,
  [claimsKeys.owner.SALUTATION]: who,
  [claimsKeys.owner.FIRST_NAME]: firstName,
  [claimsKeys.owner.LAST_NAME]: lastName,
  [claimsKeys.owner.PHONE]: phone,
  [claimsKeys.owner.EMAIL]: email,
  [claimsKeys.thirdParty.SALUTATION]: tWho,
  [claimsKeys.thirdParty.FIRST_NAME]: tFirstName,
  [claimsKeys.thirdParty.LAST_NAME]: tLastName,
  [claimsKeys.thirdParty.PHONE]: tPhone,
  [claimsKeys.thirdParty.EMAIL]: tEmail,
}) => {
  const liabilityFlow = isLiabilityFlow(whoFillsData);

  return {
    firstName: liabilityFlow ? tFirstName : firstName,
    lastName: liabilityFlow ? tLastName : lastName,
    phone: liabilityFlow ? tPhone : phone,
    email: liabilityFlow ? tEmail : email,
    isCompany: liabilityFlow ? isCompany(tWho) : isCompany(who),
  };
};

export const getIncidentDetails = ({
  [claimsKeys.WHO_FILLS]: whoFillsData,
  [claimsKeys.WHAT_HAPPENED]: whatHappened,
  [claimsKeys.KIND_OF_DAMAGE]: kindOfDamage,
  [claimsKeys.accident.LOCATION]: accidentLocation,
  [claimsKeys.accident.COUNTRY]: accidentCountry,
  [claimsKeys.DATE]: date,
  [claimsKeys.owner.LICENSE_NUMBER]: licenseNumber,
  [claimsKeys.thirdParty.LICENSE_NUMBER]: thirdPartyLicenseNumber,
  [claimsKeys.thirdParty.VEHICLE_DAMAGE]: vehicleDamage,
}) => ({
  date: getDate(date),
  accidentLocation,
  accidentCountry: getCountryNameByCode(accidentCountry),
  licenseNumber,
  whatHappened,
  kindOfDamage,
  thirdPartyLicenseNumber,
  isVehicleDamage: compareValues(vehicleDamage),
  isGlassFlow: isGlassFlow(whatHappened),
  isVandalismFlow: isVandalismFlow(whatHappened),
  isLiabilityFlow: isLiabilityFlow(whoFillsData),
});

export const getPoliceDetails = ({
  [claimsKeys.police.IS_REPORTED]: isReported,
  [claimsKeys.police.DEPARTMENT]: department,
  [claimsKeys.police.CASE_NUMBER]: caseNumber,
  [claimsKeys.ANY_WITNESS]: anyWitness,
}) => ({
  department,
  caseNumber,
  isPoliceReported: compareValues(isReported),
  isAnyWitness: compareValues(anyWitness),
});

export const getWitnessesDetails = data => {
  const arrayOfWitnessKey = ['firstWitness', 'secondWitness', 'thirdWitness'];
  const witnesses = [];
  arrayOfWitnessKey.forEach(k => {
    if (data[claimsKeys[k].FIRST_NAME]) {
      const witness = claimsKeys[k];
      witnesses.push({
        firstName: data[witness.FIRST_NAME],
        lastName: data[witness.LAST_NAME],
        phone: data[witness.PHONE],
      });
    }
  });
  return witnesses;
};

export const getVehicleDamageDetails = ({
  [claimsKeys.WHO_FILLS]: whoFillsData,
  [claimsKeys.WHAT_HAPPENED]: whatHappened,
  [claimsKeys.KIND_OF_DAMAGE]: kindOfDamage,
  [claimsKeys.owner.LICENSE_NUMBER]: licenseNumber,
  [claimsKeys.owner.CAR_MAP]: carMap,
  [claimsKeys.owner.DESCRIBE_DAMAGE]: describeDamage,
  [claimsKeys.STOLEN_CAR_PARTS]: stolenCarParts,
}) => ({
  isLiabilityFlow: isLiabilityFlow(whoFillsData),
  isWholeCarTheftFlow: isWholeCarTheftFlow(whatHappened, kindOfDamage),
  isPartsCarTheftFlow: isPartsCarTheftFlow(whatHappened, kindOfDamage),
  isGlassFlow: isGlassFlow(whatHappened),
  kindOfDamage,
  carDamages: carMap ? carMap.join(', ') : '',
  licenseNumber,
  describeDamage,
  stolenCarParts: stolenCarParts ? stolenCarParts.join(', ') : '',
});

export const getContactOfAccidentData = ({
  [claimsKeys.WHAT_HAPPENED]: whatHappened,
  [claimsKeys.COLLISION_TYPE]: collisionType,
  [claimsKeys.thirdParty.SALUTATION]: who,
  [claimsKeys.thirdParty.FIRST_NAME]: firstName,
  [claimsKeys.thirdParty.LAST_NAME]: lastName,
  [claimsKeys.thirdParty.PHONE]: phone,
  [claimsKeys.thirdParty.EMAIL]: email,
}) => ({
  isCompany: isCompany(who),
  isCollisionFlow: isCollisionFlow(whatHappened),
  isOwnCar: compareValues(collisionType, collisionTypes.OWN_CAR),
  firstName,
  lastName,
  phone,
  email,
});

export const getDamageOfAccidentDetails = ({
  [claimsKeys.WHO_FILLS]: whoFillsData,
  [claimsKeys.WHAT_HAPPENED]: whatHappened,
  [claimsKeys.COLLISION_TYPE]: collisionType,
  [claimsKeys.thirdParty.PERSONAL_INJURY]: personalInjury,
  [claimsKeys.thirdParty.PERSONAL_INJURY_DESC]: personalInjuryDesc,
  [claimsKeys.thirdParty.VEHICLE_DAMAGE]: vehicleDamage,
  [claimsKeys.thirdParty.ITEM_DAMAGE]: itemDamage,
  [claimsKeys.thirdParty.ITEM_DAMAGE_DESC]: itemDamageDesc,
  [claimsKeys.thirdParty.LICENSE_NUMBER]: licenseNumber,
  [claimsKeys.thirdParty.CAR_MAP]: carMap,
  [claimsKeys.thirdParty.DESCRIBE_DAMAGE]: describeDamage,
}) => ({
  isCollisionFlow: isCollisionFlow(whatHappened),
  isLiabilityFlow: isLiabilityFlow(whoFillsData),
  isVehicleDamage: compareValues(vehicleDamage),
  isPersonalInjury: compareValues(personalInjury),
  isItemDamage: compareValues(itemDamage),
  isOwnCar: compareValues(collisionType, collisionTypes.OWN_CAR),
  personalInjuryDesc,
  itemDamageDesc,
  licenseNumber,
  carDamages: carMap ? carMap.join(', ') : carMap,
  describeDamage,
});

export const getFileDetails = ({
  [claimsKeys.HAS_FILES]: hasFiles,
  [claimsKeys.TYPE_FILES]: fileTypes,
  [claimsKeys.FILES]: files,
}) => ({
  hasFiles: compareValues(hasFiles),
  fileTypes,
  files: files || [],
});

const getInfoTextByAppointmentInfo = isAfterAppointmentInfo => (isAfterAppointmentInfo
  ? getTranslationArray('vehicle.thank-you')['appointment-info']
  : getTranslationArray('vehicle.thank-you').info);

const confirmationEmailTemplateName = {
  [ORGANIZATIONS.HYUNDAI]: 'Hyundai_claim_confirmation',
  [ORGANIZATIONS.EMP]: 'EMP_claim_confirmation',
  [ORGANIZATIONS.BMW]: 'BMW_claim_confirmation',
  [ORGANIZATIONS.NRW_GARAGE]: 'NRW-Garage_claim_confirmation',
  [ORGANIZATIONS.VOLVO]: 'NoBranding_claim_confirmation',
  [ORGANIZATIONS.THIRD_PARTY]: 'NoBranding_claim_confirmation',
};

export const getAdditionalData = ({
  [claimsKeys.WHO_FILLS]: whoFillsData,
  [claimsKeys.owner.SALUTATION]: who,
  [claimsKeys.owner.FIRST_NAME]: firstName,
  [claimsKeys.owner.LAST_NAME]: lastName,
  [claimsKeys.owner.EMAIL]: email,
  [claimsKeys.APPOINTMENT_INFO]: appointmentInfo,
  [claimsKeys.thirdParty.FIRST_NAME]: tFirstName,
  [claimsKeys.thirdParty.LAST_NAME]: tLastName,
  [claimsKeys.thirdParty.EMAIL]: tEmail,
}) => {
  const isThirdParty = isLiabilityFlow(whoFillsData);
  const params = isThirdParty ? {
    fullName: isCompany(who) ? tLastName : `${tFirstName} ${tLastName}`,
    email: tEmail,
  } : {
    fullName: isCompany(who) ? lastName : `${firstName} ${lastName}`,
    email,
  };

  return {
    brandName: organizationName,
    emailData: {
      ...{
        templateName: confirmationEmailTemplateName[organizationName],
        date: getDate(new Date()),
        currentYear: (new Date().getFullYear()).toString(),
        infoText: getInfoTextByAppointmentInfo(appointmentInfo),
      },
      ...params,
    },
  };
};
