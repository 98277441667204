import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { useDispatch } from 'react-redux';
import withSingleFieldValidation from '../../hocs/withSingleFieldValidation';
import { types } from '../../actions/constants';
import { YES_VALUE } from '../../helpers/formData/formData';

const InformationText = ({
  getValidationProps, pathName,
}) => {
  const {
    name, onChange,
  } = getValidationProps();
  const dispatch = useDispatch();

  useEffect(() => {
    onChange(name)(YES_VALUE);
    dispatch({
      type: types.FIELD_UPDATE,
      fieldName: name,
      payload: YES_VALUE,
      pathName,
    });
  }, []);

  return (
    <div className="esc_grid">
      <div className="esc_grid__wrapper">
        <div className="esc_col esc_col-12" />
      </div>
    </div>
  );
};

InformationText.propTypes = {
  getValidationProps: PropTypes.func,
  pathName: PropTypes.string,
};

InformationText.contextTypes = {
  t: PropTypes.func,
};
InformationText.defaultProps = {
  getValidationProps: () => { },
  pathName: '',
};

export { InformationText as InformationTextPlain };
export default compose(
  withSingleFieldValidation,
)(InformationText);
