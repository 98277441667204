/* global VERSION:false */
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { ThemeProvider } from 'react-jss';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider as ErgoProvider } from '@eg/elements/Provider';
import store from './store';
import App from './App/App';
import '@eg/elements/styles/ergo-one/core.css';
import './styles/styles.css';
import './styles/reset.css';
import './styles/base.css';
import * as theme from './styles/theme.jss';
import history from './browserHistory';
import { organizationTheme } from './services/organizationService';

const rootComponent = (
  <Provider store={store}>
    <Router history={history}>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_SITE_KEY}>
        <ErgoProvider theme="ergo-one">
          <ThemeProvider theme={{ ...theme, ...organizationTheme }}>
            <App />
          </ThemeProvider>
        </ErgoProvider>
      </GoogleReCaptchaProvider>
    </Router>
  </Provider>
);

ReactDOM.render(rootComponent, document.getElementById('app'));

if (process.env.NODE_ENV === 'development') {
  console.log(`v${process.env.VERSION}`); //eslint-disable-line
}
