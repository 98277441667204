export const maskTypes = {
  PHONE_NUMBER: 'MLLLLLLLLLLLLLL',
  IBAN: '1111 1111 1111 1111 11',
  LICENSE_NUMBER_PART_1: 'LLL',
  LICENSE_NUMBER_PART_2: 'LL',
  LICENSE_NUMBER_PART_3: '1111A',
  POST_CODE: '11111',
  POLICY_NUMBER: 'KR111111111',
};

export const formatCharactersToMask = {
  PHONE_NUMBER: {
    L: {
      validate: (char) => /[0-9()/ -]/.test(char),
    },
    M: {
      validate: (char) => /[0(]/.test(char),
    },
  },
  LICENSE_NUMBER_PART: {
    L: {
      validate: (char) => /[a-zA-ZäöüÄÖÜß]/.test(char),
      transform: (char) => char.toUpperCase(),
    },
  },
  LICENSE_NUMBER_PART_3: {
    A: {
      validate: (char) => /[eE]/.test(char),
      transform: (char) => char.toUpperCase(),
    },
  },
};

export default maskTypes;
