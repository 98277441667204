import React, { useState } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import SvgIcon from '@eg/elements/SvgIcon';
import ChevronLeftIcon from '@eg/elements/Icons/ChevronLeftIcon';
import { ErgoElementsContext } from '@eg/elements/Provider';
import MessageBox from '@eg/elements/MessageBox';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';
import saveClaimData from '../../services/saveClaimService/saveClaimService';
import { phoneNumber } from '../../services/organizationService';
import { mapClaimData } from '../../services';
import { NAV_DIRECTIONS } from '../../actions/constants';
import { navigateToPath } from '../../actions/navigation';
import {
  startLoading as startLoadingAction,
  stopLoading as stopLoadingAction,
} from '../../actions/loader';
import LicenseNumberMaskedText from './LicenseNumberMaskedText';
import ErgoNavButton from '../../components/ErgoNavButton';
import { maskTypes, formatCharactersToMask } from '../../helpers/masks';
import { getFirstStepData } from '../../helpers/formData/vehicleFormData';
import Stars from '../../assets/icons/Stars';
import styles from './styles.jss';

const LicenseNumber = (props, { t }) => {
  const {
    name,
    localePath,
    classes,
    isValid,
    getValidationProps,
    pathName,
    formData,
    goForward,
    startLoading,
    stopLoading,
  } = props;
  const mockedConfigs = [
    {
      arrayKey: 0,
      mask: maskTypes.LICENSE_NUMBER_PART_1,
      className: classes.part1,
      formatCharacters: formatCharactersToMask.LICENSE_NUMBER_PART,
      placeholder: t(`${localePath}.placeholders.label1`),
      validationProps: 'part1',
      pathName,
      pageName: name,
    },
    {
      arrayKey: 1,
      mask: maskTypes.LICENSE_NUMBER_PART_2,
      className: classes.part2,
      formatCharacters: formatCharactersToMask.LICENSE_NUMBER_PART,
      placeholder: t(`${localePath}.placeholders.label2`),
      validationProps: 'part2',
      pathName,
      pageName: name,
    },
    {
      arrayKey: 2,
      mask: maskTypes.LICENSE_NUMBER_PART_3,
      className: classes.part3,
      formatCharacters: formatCharactersToMask.LICENSE_NUMBER_PART_3,
      placeholder: t(`${localePath}.placeholders.label3`),
      validationProps: 'part3',
      pathName,
      pageName: name,
    },
  ];
  const [error, setError] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();


  const handleChange = async () => {
    setError(false);
    startLoading();
    const { data } = mapClaimData(formData, pathName);
    const resource = saveClaimData();
    try {
      let recaptchaToken = '';
      if (executeRecaptcha) {
        recaptchaToken = await executeRecaptcha('licenceNumberCheck');
      }
      const res = await resource.check({ ...getFirstStepData({ ...data, recaptchaToken }) });
      if (res.data.status === 'success') {
        goForward();
      } else {
        setError(true);
      }
    // eslint-disable-next-line no-empty
    } catch { } finally {
      stopLoading();
    }
  };

  return (
    <>
      <div className="esc_grid">
        <div className={`esc_grid__wrapper ${classes.wrapper}`}>
          <div className={`esc_col esc_col-12 ${classes.headLabel}`}>
            {t(`${localePath}.placeholders.headLabel`)}
          </div>
          <div className={`${classes.rectangle}`}>
            <ErgoElementsContext.Consumer>
              {({ idGenerator }) => (
                <SvgIcon
                  idGenerator={idGenerator}
                  viewBox="0 0 15 14"
                  title={t('content.main-title')}
                  width={15}
                  height={14}
                  className={classes.icon}
                >
                  <Stars />
                </SvgIcon>
              )}
            </ErgoElementsContext.Consumer>
            <span className={classes.letter}>D</span>
          </div>
          {mockedConfigs.map(configInfo => (
            <div key={configInfo.arrayKey} className="esc_col-3 esc_col-s-2">
              <LicenseNumberMaskedText {...configInfo} {...getValidationProps(configInfo.validationProps)} />
            </div>
          ))}
        </div>
      </div>
      { error && (
        <div className="esc_grid">
          <div className={`esc_grid__wrapper ${classes.wrapper}`}>
            <div className="esc_col esc_col-12">
              <MessageBox type="info" className={classes.messageBox}>
                {t(`${localePath}.errorMessage`, { phoneNumber })}
              </MessageBox>
            </div>
          </div>
        </div>
      )}
      <nav className="esc_grid">
        <div className={`${classes.nowrap} esc_grid__wrapper`}>
          <div className="esc_col esc_col-12 esc_col-s-3 esc_col-m-3">
            <ErgoNavButton
              previous
              variant="secondary"
              iconLeft={ChevronLeftIcon}
              size="normal"
            />
          </div>
          <div className="esc_col esc_col-12 esc_col-s-3 esc_col-m-3">
            <ErgoNavButton
              next
              disabled={!isValid}
              size="normal"
              type="submit"
              shouldNavigateAfterClick={false}
              onClick={handleChange}
            />
          </div>
        </div>
      </nav>
    </>
  );
};

LicenseNumber.propTypes = {
  name: PropTypes.string,
  pathName: PropTypes.string,
  localePath: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  isValid: PropTypes.bool,
  getValidationProps: PropTypes.func,
  formData: PropTypes.shape(Object),
  goForward: PropTypes.func,
  startLoading: PropTypes.func,
  stopLoading: PropTypes.func,
};

LicenseNumber.defaultProps = {
  name: '',
  pathName: '',
  localePath: '',
  classes: {},
  isValid: false,
  getValidationProps: () => {},
  formData: {},
  goForward: () => {},
  startLoading: () => {},
  stopLoading: () => {},
};

LicenseNumber.contextTypes = {
  t: PropTypes.func,
};

const mapStateToProps = ({ formData }) => ({ formData });

const mapDispatchToProps = (dispatch) => ({
  startLoading: () => dispatch(startLoadingAction()),
  stopLoading: () => dispatch(stopLoadingAction()),
  goForward: () => dispatch(navigateToPath(NAV_DIRECTIONS.NEXT)),
});

export { LicenseNumber as LicenseNumberPlain };
export default compose(
  withMultiFieldValidation,
  injectSheet(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(LicenseNumber);
