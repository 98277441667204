import workshopLookup from '../services/workshopLookup/workshopLookup';
import { fieldRemove } from './formData';
import { types } from './constants';
import { startLoading, stopLoading } from './loader';

export const fetchWorkshopsStart = postalCode => ({
  type: types.FETCH_WORKSHOPS,
  postalCode,
});

export const fetchWorkshopsSuccess = workshopList => ({
  type: types.FETCH_WORKSHOPS_SUCCESS,
  workshopList,
});

export const fetchWorkshopsFailure = fetchError => ({
  type: types.FETCH_WORKSHOPS_FAILURE,
  fetchError,
});

export const fetchWorkshops = (postalCode, pathName, fieldName) => async (dispatch) => {
  try {
    dispatch(startLoading());
    dispatch(fieldRemove(pathName, fieldName));
    dispatch(fetchWorkshopsStart(postalCode));
    const { data } = await workshopLookup(postalCode);
    const workshopList = Object.values(data);
    dispatch(fetchWorkshopsSuccess(workshopList));
    dispatch(stopLoading());
  } catch (err) {
    const fetchError = err.message || err;
    dispatch(fetchWorkshopsFailure(fetchError));
    dispatch(stopLoading());
  }
};

export const updatePostalCode = postalCode => ({
  type: types.UPDATE_WORKSHOP_POSTAL_CODE,
  postalCode,
});
