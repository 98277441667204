import React from 'react';
import { compose } from '@shakacode/recompose';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import { connect } from 'react-redux';
import ChevronLeftIcon from '@eg/elements/Icons/ChevronLeftIcon';
import ErgoNavButton from '../../components/ErgoNavButton';
import styles from './styles.jss';

const Navigation = ({
  classes, isNextActive, isPreviousActive, onNext, params = {},
}) => {
  const { isNextHidden, nextLabel, prevLabel } = params;
  return (
    <nav className="esc_grid">
      <div className={`${classes.nowrap} esc_grid__wrapper`}>
        <div className="esc_col esc_col-12 esc_col-s-3 esc_col-m-3">
          <ErgoNavButton
            previous
            variant="secondary"
            iconLeft={ChevronLeftIcon}
            size="normal"
            disabled={!isPreviousActive}
            title={prevLabel}
          />
        </div>
        {!isNextHidden && (
          <div className="esc_col esc_col-12 esc_col-s-3 esc_col-m-3">
            <ErgoNavButton
              next
              disabled={!isNextActive}
              size="normal"
              onClick={onNext}
              title={nextLabel}
            />
          </div>
        )}
      </div>
    </nav>
  );
};

const mapStateToProps = ({ navigation }) => ({
  isNextActive: navigation.isNextActive,
  isPreviousActive: navigation.isPreviousActive,
});

Navigation.propTypes = {
  isNextActive: PropTypes.bool,
  isPreviousActive: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string),
  onNext: PropTypes.func,
  params: PropTypes.shape({
    nextLabel: PropTypes.string,
    prevLabel: PropTypes.string,
    isNextHidden: PropTypes.bool,
  }),
};

Navigation.defaultProps = {
  classes: {},
  isNextActive: false,
  isPreviousActive: true,
  onNext: () => { },
  params: {},
};

export { Navigation as NavigationPlain };
export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(Navigation);
