import React from 'react';
import injectSheet from 'react-jss';
import { topBarProps } from '../../models/layouts';
import { logo } from '../../services/organizationService';
import styles from './styles.jss';

const TopBar = ({
  classes,
}) => (
  <div className={classes.topBarBox}>
    <img className={classes.logo} src={logo} alt="logo" />
  </div>
);
TopBar.propTypes = topBarProps.props;
TopBar.defaultProps = topBarProps.defaults;
TopBar.contextTypes = topBarProps.contexts;

export { TopBar as TopBarPlain };
export default injectSheet(styles)(TopBar);
