import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@shakacode/recompose';
import RepairShopCard from '../RepairShopCard';
import { types } from '../../../actions/constants';
import decodeHtmlEntities from '../../../helpers/decodeHTMLEntities';

const RepairShopSelect = ({
  workshopList,
  selected,
  name,
  pathName,
  onChange,
  goForward,
  updateField,
}) => {
  const onSelected = (value) => () => {
    const target = { name, type: 'text', value };
    updateField(name, value, pathName);
    onChange({ target });
    setTimeout(goForward, 0);
  };

  const cards = workshopList.map((workshop) => (
    <RepairShopCard
      key={workshop.repairerid}
      name={decodeHtmlEntities(workshop.name)}
      addressStreet={workshop.address}
      addressCity={`${workshop.postalcode} ${workshop.city}`}
      phone={workshop.phone}
      distance={`${workshop.distance} km`}
      onSelected={onSelected(workshop)}
      selected={selected.repairerid === workshop.repairerid}
    />
  ));

  return (
    <div className="esc_grid">
      <div className="esc_grid__wrapper">
        { cards }
      </div>
    </div>
  );
};

RepairShopSelect.propTypes = {
  workshopList: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.objectOf(PropTypes.string),
  onChange: PropTypes.func,
  name: PropTypes.string,
  pathName: PropTypes.string,
  goForward: PropTypes.func,
  updateField: PropTypes.func,
};

RepairShopSelect.defaultProps = {
  name: '',
  workshopList: [],
  selected: {},
  pathName: '',
  onChange: () => {},
  goForward: () => {},
  updateField: () => {},
};

const mapDispatchToProps = dispatch => ({
  updateField: (name, payload, pathName) => dispatch({
    type: types.FIELD_UPDATE,
    fieldName: name,
    pathName,
    payload,
  }),
});

export { RepairShopSelect as RepairShopSelectPlain };
export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(RepairShopSelect);
