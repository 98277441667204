import commonValidators from './common.validators';
import mathValidators from './math.validators';
import dateValidators from './date.validators';
import countryValidators from './country.validators';
import identityValidators from './identity.validators';
import ibanValidators from './iban.validators';
import policyNumberValidators from './policynumber.validators';
import claimNumberValidators from './claimNumber.validators';

export default {
  ...commonValidators,
  ...mathValidators,
  ...dateValidators,
  ...countryValidators,
  ...identityValidators,
  ...ibanValidators,
  ...policyNumberValidators,
  ...claimNumberValidators,
};
