const styles = ({ colors, breakpoints }) => ({
  detailsWrapper: {
    textAlign: 'left',
    marginBottom: 24,
    border: '1px solid #009284',
    borderRadius: 9,
    boxShadow: `0 1px 21px 0 ${colors.themeLightestGray}`,
    backgroundColor: colors.themeWhite,
    '& .ee_details__summary': {
      borderBottom: 'none !important',
      borderRadius: 9,
      fontSize: '16px',
      lineHeight: '24px',
      '&:hover > svg, &:focus > svg': {
        color: `${colors.themeDarkestGray} !important`,
      },
    },
    '& .ee_details__title': {
      fontWeight: 'bold',
      '& .ee_details__left-icon': {
        width: '36px',
        height: '36px',
      },
    },
    '& .ee_details__right-icon': {
      fill: colors.themeDarkestGray,
    },
    '& .ee_details__content': {
      padding: '0 42px 1em 66px !important',
      border: 'none',
      borderStyle: 'hidden !important',
    },
  },

  gridWrapper: {
    marginTop: '20px !important',
    [`@media (max-width: ${breakpoints.breakpointS})`]: {
      '& > :nth-child(1)': {
        order: '1',
      },
      '& > :nth-child(2)': {
        order: '3',
      },
      '& > :nth-child(3)': {
        order: '2',
      },
    },
  },
});

export default styles;
