import API from '../api';

class CommonService {
  constructor(serviceType, resourceType) {
    this.resourceType = `${serviceType}/${resourceType}`;
  }

  static axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  create(data) {
    return API.post(this.resourceType, data, this.axiosConfig);
  }

  delete(id) {
    return API.delete(this.resourceType + id);
  }

  deleteWithPayload(payload) {
    return API.delete(this.resourceType, { data: payload });
  }

  get(id) {
    return API.get(this.resourceType + id);
  }

  check(data) {
    return API.put(`${this.resourceType}/check`, data);
  }

  list(data) {
    return API.get(this.resourceType, data);
  }
}

export default CommonService;
