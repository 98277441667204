import PropTypes from 'prop-types';

const optionTypes = {
  MALE: 'male',
  FEMALE: 'female',
  COMPANY: 'company',
};

const ContactDataResource = PropTypes.shape({
  who: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
});

const props = {
  classes: PropTypes.objectOf(PropTypes.string),
  localePath: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  pathName: PropTypes.string,
  data: ContactDataResource,
  getValidationProps: PropTypes.func,
};

const defaults = {
  classes: {},
  localePath: '',
  options: Object.values(optionTypes),
  pathName: '',
  data: {
    who: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
  },
  getValidationProps: () => {},
};

export default {
  optionTypes, props, defaults, ContactDataResource,
};
