import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgDokument } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getVehicleDamageDetails } from '../../../helpers/formData/vehicleFormData';

const VehicleDamageDetails = ({ formData, localePath, className }, { t }) => {
  const {
    isLiabilityFlow,
    carDamages,
    licenseNumber,
    describeDamage,
  } = getVehicleDamageDetails(formData);
  if (isLiabilityFlow || !carDamages) return false;

  return (
    <Details
      iconLeft={SvgDokument}
      summary={t(`${localePath}.vehicle-damage.title`)}
      className={className}
    >
      <p>
        {t(`${localePath}.vehicle-damage.content`, {
          licenseNumber,
          describeDamage: describeDamage
            ? t(`${localePath}.vehicle-damage.describe-damage`, { describeDamage }) : '',
          carDamages,
        })}
      </p>
    </Details>
  );
};

VehicleDamageDetails.propTypes = legalConsentProps.sectionProps;
VehicleDamageDetails.defaultProps = legalConsentProps.sectionDefaults;

VehicleDamageDetails.contextTypes = {
  t: PropTypes.func,
};

export default VehicleDamageDetails;
