import { addSegments } from '../../../helpers/flow/flow';
import flow from './main.yaml';

// path
import liability from '../Vehicle/segments/liability.yaml';
// segments
import liabilityIdentification from '../Vehicle/segments/liability-identification.yaml';
import accidentDetails from '../Vehicle/segments/accident-details.yaml';
import policeWithNoReport from '../Vehicle/segments/police-with-no-report.yaml';
import areThereAnyWitnesses from '../Vehicle/segments/are-there-any-witnesses.yaml';
import witness from '../Vehicle/segments/witness.yaml';
import liabilityDamages from '../Vehicle/segments/liability-damages.yaml';
import vehicleDamage from '../Vehicle/segments/vehicle-damage.yaml';
import documentUpload from '../Vehicle/segments/document-upload.yaml';

const segments = {
  liability,
  // segments
  liabilityIdentification,
  accidentDetails,
  policeWithNoReport,
  areThereAnyWitnesses,
  witness,
  liabilityDamages,
  vehicleDamage,
  documentUpload,
};

export default addSegments(flow, segments);
