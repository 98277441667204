const styles = ({ colors }) => ({
  item: {
    paddingBottom: 30,
    position: 'relative',
    zIndex: 70,
    display: 'list-item',
    textAlign: 'left',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: colors.themeWhite,
    border: `5px solid ${colors.themeWhite}`,
    fontWeight: 'bold',
  },
  icon: {
    fill: `${colors.extendedIconBlack} !important`,
    color: `${colors.extendedIconBlack} !important`,
    padding: 10,
    border: `3px solid ${colors.themePrimary}`,
    borderRadius: '50%',
    marginRight: 20,
    margin: '3px 0',
  },
  text: {
    width: '80%',
  },
});

export default styles;
