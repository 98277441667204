import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { compose } from '@shakacode/recompose';
import withMultiFieldValidation from '../../hocs/withMultiFieldValidation';
import { types } from '../../actions/constants';
import ErgoTextArea from '../../components/ErgoTextArea';
import CarArea from './CarArea';

import styles from './styles.jss';

class CarMap extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const { data, getValidationProps } = this.props;
    const { onChange, name } = getValidationProps('carMap');
    const target = {
      name,
      value: data.carMap,
    };
    if (prevProps.data.carMap !== data.carMap) {
      onChange({ target });
    }
  }

  render() {
    const {
      localePath, classes, options, name, updateField, pathName, getValidationProps, data,
    } = this.props;
    const { t } = this.context;

    return (
      <div className="esc_grid">
        <div className={`esc_grid__wrapper ${classes.wrapper}`}>
          <div className="esc_col esc_col-12 esc_col-m-5">
            <div className={classes.carPicture}>
              {options.map((option, key) => (
                <CarArea
                  name={name}
                  key={option}
                  option={option}
                  localePath={localePath}
                  tabIndex={key}
                  onClick={() => updateField(name, option, pathName, 'carMap')}
                  selected={data.carMap.includes(option)}
                />
              ))}
            </div>
          </div>
          <div className="esc_col esc_col-12 esc_col-m-7">
            <ErgoTextArea
              {...getValidationProps('describeDamage')}
              minRows={13}
              placeholder={t(`${localePath}.describe-damage.placeholder`)}
              description={t(`${localePath}.describe-damage.description`)}
            />
          </div>
        </div>
      </div>
    );
  }
}

CarMap.propTypes = {
  localePath: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.string),
  pathName: PropTypes.string,
  data: PropTypes.shape({
    carMap: PropTypes.arrayOf(PropTypes.string),
    describeDamage: PropTypes.string,
  }),
  name: PropTypes.string,
  updateField: PropTypes.func,
  getValidationProps: PropTypes.func,
  onChange: PropTypes.func,
};

CarMap.contextTypes = {
  t: PropTypes.func,
};

CarMap.defaultProps = {
  localePath: '',
  classes: {},
  options: [
    'front-left',
    'front-middle',
    'front-right',
    'middle-left',
    'middle-part',
    'middle-right',
    'back-left',
    'back-middle',
    'back-right',
  ],
  name: '',
  pathName: '',
  data: {
    carMap: [],
    describeDamage: '',
  },
  updateField: () => {},
  getValidationProps: () => {},
  onChange: () => {},
};

export { CarMap as CarMapPlain };

/* istanbul ignore next */
// const mapStateToProps = ({ formData }) => ({ formData });
const mapStateToProps = ({ formData }, ownProps) => ({ data: formData[ownProps.pathName][ownProps.name] });
/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  updateField: (name, payload, pathName, subName) => {
    dispatch({
      type: types.FIELD_UPDATE_MULTI,
      fieldName: name,
      pathName,
      payload,
      subName,
    });
  },
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectSheet(styles),
  withMultiFieldValidation,
)(CarMap);
