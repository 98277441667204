import {
  getPhoneValue,
  getLastNameValue,
  getFirstNameValue,
  getOneFromMultiProxy,
  getSalutationValue,
  arrayIncludesValueProxy,
  getLicenseNumber,
  getCarMapValuesProxy,
  getYesNoValue,
  getCountryCode,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import {
  collisionDamages,
  collisionTypes,
  vehicleTypes,
} from '../../../helpers/formData/vehicleFormData';

export default {
  'collision_kind-of-damage': [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      collision: collisionDamages.COLLISION,
      'property-damage': collisionDamages.PROPERTY_DAMAGE,
      'personal-injury': collisionDamages.PERSONAL_INJURY,
      'property-damage-without-motor': collisionDamages.PROPERTY_DAMAGE_WITHOUT_MOTOR,
      'personal-injury-without-motor': collisionDamages.PERSONAL_INJURY_WITHOUT_MOTOR,
    }),
  ],
  'collision_collision-type': [
    claimsKeys.COLLISION_TYPE,
    getOneFromMultiProxy({
      'own-car': collisionTypes.OWN_CAR,
      'other-car-object-person': collisionTypes.OTHER_CAR_OBJECT_PERSON,
      both: collisionTypes.BOTH,
    }),
  ],
  // ---- THIRD PARTY
  'collision_third-party_contact': {
    who: [claimsKeys.thirdParty.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.thirdParty.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.thirdParty.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.thirdParty.PHONE, getPhoneValue],
    email: claimsKeys.thirdParty.EMAIL,
  },
  'collision_third-party_is-known': [claimsKeys.thirdParty.IS_KNOWN, getYesNoValue],
  'collision_third-party_address': {
    street: claimsKeys.thirdParty.STREET,
    houseNo: claimsKeys.thirdParty.HOUSE_NO,
    postCode: claimsKeys.thirdParty.POST_CODE,
    city: claimsKeys.thirdParty.CITY,
    country: [claimsKeys.thirdParty.COUNTRY, getCountryCode],
  },
  'collision_third-party_kind-of-damage': {
    'personal-injury': [claimsKeys.thirdParty.PERSONAL_INJURY, arrayIncludesValueProxy('personal-injury')],
    'vehicle-damage': [claimsKeys.thirdParty.VEHICLE_DAMAGE, arrayIncludesValueProxy('vehicle-damage')],
    'item-damage': [claimsKeys.thirdParty.ITEM_DAMAGE, arrayIncludesValueProxy('item-damage')],
  },
  'collision_third-party_person-damage': claimsKeys.thirdParty.PERSONAL_INJURY_DESC,
  'collision_third-party_item-damage': claimsKeys.thirdParty.ITEM_DAMAGE_DESC,
  'collision_third-party_vehicle-damage_vehicle-type': [
    claimsKeys.thirdParty.VEHICLE_TYPE,
    getOneFromMultiProxy({
      car: vehicleTypes.CAR,
      truck: vehicleTypes.TRUCK,
      motorcycle: vehicleTypes.MOTORCYCLE,
      other: vehicleTypes.OTHER,
    }),
  ],
  'collision_third-party_vehicle-damage_license-number': [claimsKeys.thirdParty.LICENSE_NUMBER, getLicenseNumber],
  'collision_third-party_vehicle-damage_car-map': {
    describeDamage: claimsKeys.thirdParty.DESCRIBE_DAMAGE,
    carMap: [claimsKeys.thirdParty.CAR_MAP, getCarMapValuesProxy],
  },
  'collision_third-party_vehicle-damage_description': claimsKeys.thirdParty.DESCRIBE_DAMAGE,
};
