import { UploadFileService } from '../services';
import { types } from './constants';
import { startLoading, stopLoading } from './loader';

export const removeFile = (fileId, name, pathName) => ({
  type: types.FILE_UPLOAD_REMOVE,
  fieldName: name,
  pathName,
  payload: fileId,
});

export const addFile = (name, payload, pathName) => ({
  type: types.FILE_UPLOAD_ADD,
  fieldName: name,
  pathName,
  payload,
});

// Prepared for loader
export const addFileAction = (data, name, pathName, fileName) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const { data: resultData } = await UploadFileService.create(data);
    const payload = { ...resultData, fileName };
    dispatch(addFile(name, payload, pathName));
    dispatch(stopLoading());
    return '';
  } catch (e) {
    dispatch(stopLoading());
    return 'error-add-file';
  }
};

export const removeFileAction = (location, name, pathName) => async dispatch => {
  try {
    dispatch(startLoading());
    await UploadFileService.deleteWithPayload({ location });
    dispatch(removeFile(location, name, pathName));
    dispatch(stopLoading());
    return '';
  } catch (e) {
    dispatch(stopLoading());
    return 'error-remove-file';
  }
};
