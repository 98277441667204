import {
  getOneFromMultiProxy,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { fireDamages } from '../../../helpers/formData/vehicleFormData';

export default {
  'fire-or-explosion_kind-of-damage': [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      fire: fireDamages.FIRE,
      explosion: fireDamages.EXPLOSION,
      'short-circuit': fireDamages.SHORT_CIRCUIT,
    }),
  ],
};
