import {
  getOneFromMultiProxy,
  getYesNoValue,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { animalDamages } from '../../../helpers/formData/vehicleFormData';

export default {
  'animal-damage_kind-of-damage': [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      yes: animalDamages.WILD_ANIMALS,
      no: animalDamages.OTHER_ANIMALS,
      bit: animalDamages.BITE,
    }),
  ],
  'animal-damage_police-with-report-is-reported': [claimsKeys.police.FORESTER_IS_REPORTED, getYesNoValue],
};
