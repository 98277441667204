import { mediaQuery } from '../../styles/helpers';
import { breakpoints } from '../../styles/constants';

const styles = () => ({
  impressumPage: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  container: {
    margin: '0px auto 80px',
    paddingTop: '120px',
    maxWidth: '80%',
    flexGrow: 1,
  },
  heading: {
    fontSize: 28,
    color: 'black',
    marginBottom: 32,
  },
  list: {
    listStyleType: 'none',
    paddingRight: '10px',
  },
  contentItem: {
    marginBottom: '20px',
  },
  link: {
    color: '#022D60',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  horizontalInnerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    ...mediaQuery.max(breakpoints.bigMobile, {
      flexDirection: 'column',
    }),
  },
});

export default styles;
