import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-maskedinput';
import injectSheet from 'react-jss';
import styles from '../../components/InputText/styles.jss';
import ValidationMessage from '../../components/ValidationMessage/ValidationMessage';
/**
 *
 * Date container responsible for fetching data and dispatching actions to make a change to application state.
 *
 * @param title
 * @param onChange
 * @param data
 * @param t {function} - Polyglot `t()` function retrieved via context API
 * @returns {ReactElement}
 * @constructor
 */
class MaskedText extends React.PureComponent {
  constructor(props) {
    super();

    this.state = {
      labelAsPlaceholder: !props.value,
      value: props.value,
    };

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleFocus(e) {
    const { onFocus } = this.props;
    this.setState({
      labelAsPlaceholder: false,
    });
    onFocus(e);
  }

  handleBlur(e) {
    const { onBlur } = this.props;
    const { value } = this.state;
    if (!value) {
      this.setState({
        labelAsPlaceholder: true,
      });
    }
    onBlur(e);
  }

  handleChange(e) {
    const { onChange } = this.props;
    this.setState({
      value: e.target.value,
    });

    onChange(e);
  }

  render() {
    const {
      value,
      name,
      label,
      placeholder,
      mockPlaceholder,
      classes,
      className,
      mask,
      placeholderChar,
      formatCharacters,
      inputSize,
      error,
    } = this.props;

    const { labelAsPlaceholder } = this.state;

    const errorClass = error ? classes.error : '';
    let labelClasses = classes.labelText;

    if (labelAsPlaceholder) {
      labelClasses += ` ${classes.placeholderLabel}`;
      if (placeholder) {
        labelClasses += ` ${classes.labelPushRight}`;
      }
    } else {
      labelClasses += ` ${classes.topLabel}`;
    }

    return (
      <label htmlFor="text" className={`${classes.label} ${classes[inputSize]} ${className} ${errorClass}`.trim()}>
        <p className={classes.mockPlaceholder}>
          {mockPlaceholder}
        </p>
        <p className={labelClasses}>
          {label}
        </p>
        <MaskedInput
          value={value}
          className={classes.input}
          name={name}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          onChange={this.handleChange}
          type="text"
          mask={mask}
          placeholder={placeholder}
          placeholderChar={placeholderChar}
          formatCharacters={formatCharacters}
        />
        <div className={classes.inputBorderBox} />

        <ValidationMessage message={error} />
      </label>
    );
  }
}

MaskedText.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  mockPlaceholder: PropTypes.string,
  placeholderChar: PropTypes.string,
  formatCharacters: PropTypes.objectOf(PropTypes.object),
  mask: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  inputSize: PropTypes.oneOf([
    'small', 'medium', 'large', 'xlarge',
  ]),
  error: PropTypes.string,
};

MaskedText.defaultProps = {
  name: 'button',
  label: '',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  value: '',
  placeholder: '',
  mockPlaceholder: '',
  error: '',
  placeholderChar: ' ',
  formatCharacters: {},
  classes: {},
  className: '',
  inputSize: 'medium',
};

export { MaskedText as MaskedTextPlain };
export default injectSheet(styles)(MaskedText);
