const paths = {
  HOME: '/schadenmeldung/',
  SOURCE: '/schadenmeldung/:source',
  CLAIM_END: '/schadenmeldung/claim/:pathName/thank-you',
  CLAIM_START: '/schadenmeldung/claim/:pathName',
  CLAIM: '/schadenmeldung/claim/:pathName/:name',
  SHARE: '/schadenmeldung/share/:token',
  NOT_FOUND: '/schadenmeldung/404/',
  HOME_NO_SLASH: 'schadenmeldung',
  CLAIM_PARTIAL: '/schadenmeldung/claim',
  UPLOAD_SCREEN_FROM: '/schadenmeldung/upload/:screen',
  UPLOAD_SCREEN_TO: '/schadenmeldung/claim/upload/:screen',
  UPLOAD_FROM: '/schadenmeldung/upload',
  UPLOAD_TO: '/schadenmeldung/claim/upload',
  IMPRESSUM: '/schadenmeldung/impressum',
};

export const getRoot = path => path.split('/')[1];

export default paths;
