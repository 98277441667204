import {
  getOneFromMultiProxy,
  arrayIncludesValueProxy,
  getLicenseNumber,
  stripSpacesFromIban,
  getYesNoValue,
  getPhoneValue,
  getCarMapValuesProxy,
  getSalutationValue,
  getFirstNameValue,
  getLastNameValue,
  getCountryCode,
} from '../claimData.helpers';
import { claimsKeys } from '../../../helpers/formData/formData';
import { collisionDamages, vehicleTypes } from '../../../helpers/formData/vehicleFormData';

export default {
  'liability_kind-of-damage': [
    claimsKeys.KIND_OF_DAMAGE,
    getOneFromMultiProxy({
      collision: collisionDamages.COLLISION,
      'property-damage': collisionDamages.PROPERTY_DAMAGE,
      'personal-injury': collisionDamages.PERSONAL_INJURY,
      'property-damage-without-motor': collisionDamages.PROPERTY_DAMAGE_WITHOUT_MOTOR,
      'personal-injury-without-motor': collisionDamages.PERSONAL_INJURY_WITHOUT_MOTOR,
    }),
  ],
  'liability_kind-of-damages': {
    'personal-injury': [claimsKeys.thirdParty.PERSONAL_INJURY, arrayIncludesValueProxy('personal-injury')],
    'vehicle-damage': [claimsKeys.thirdParty.VEHICLE_DAMAGE, arrayIncludesValueProxy('vehicle-damage')],
    'item-damage': [claimsKeys.thirdParty.ITEM_DAMAGE, arrayIncludesValueProxy('item-damage')],
  },
  liability_identification_contact: {
    who: [claimsKeys.thirdParty.SALUTATION, getSalutationValue],
    firstName: [claimsKeys.thirdParty.FIRST_NAME, getFirstNameValue],
    lastName: [claimsKeys.thirdParty.LAST_NAME, getLastNameValue],
    phoneNumber: [claimsKeys.thirdParty.PHONE, getPhoneValue],
    email: claimsKeys.thirdParty.EMAIL,
  },
  liability_identification_address: {
    street: claimsKeys.thirdParty.STREET,
    houseNo: claimsKeys.thirdParty.HOUSE_NO,
    postCode: claimsKeys.thirdParty.POST_CODE,
    city: claimsKeys.thirdParty.CITY,
    country: [claimsKeys.thirdParty.COUNTRY, getCountryCode],
  },
  'liability_person-damage': claimsKeys.thirdParty.PERSONAL_INJURY_DESC,
  'liability_item-damage': claimsKeys.thirdParty.ITEM_DAMAGE_DESC,
  'liability_vehicle-damage_vehicle-type': [
    claimsKeys.thirdParty.VEHICLE_TYPE,
    getOneFromMultiProxy({
      car: vehicleTypes.CAR,
      truck: vehicleTypes.TRUCK,
      motorcycle: vehicleTypes.MOTORCYCLE,
      other: vehicleTypes.OTHER,
    }),
  ],
  'liability_vehicle-damage_license-number': [claimsKeys.thirdParty.LICENSE_NUMBER, getLicenseNumber],
  'liability_vehicle-damage_car-map': {
    describeDamage: claimsKeys.thirdParty.DESCRIBE_DAMAGE,
    carMap: [claimsKeys.thirdParty.CAR_MAP, getCarMapValuesProxy],
  },
  'liability_vehicle-damage_description': claimsKeys.thirdParty.DESCRIBE_DAMAGE,
  'liability_third-party_is-private-car': [claimsKeys.IS_PRIVATE_CAR, getYesNoValue],
  'liability_third-party_leased-car': [claimsKeys.LEASED_CAR, getYesNoValue],
  'liability_third-party_is-repaired': [claimsKeys.IS_REPAIRED, getYesNoValue],
  'liability_third-party_repaired-iban': {
    who: [claimsKeys.accountHolder.SALUTATION, getSalutationValue],
    name: claimsKeys.accountHolder.NAME,
    firstName: claimsKeys.accountHolder.FIRST_NAME,
    iban: [claimsKeys.accountHolder.IBAN_NUMBER, stripSpacesFromIban],
  },
  'liability_third-party_want-to-repair': [claimsKeys.WANT_TO_REPAIR, getYesNoValue],
  'liability_third-party_is-in-workshop': [claimsKeys.IS_IN_WORKSHOP, getYesNoValue],
  'liability_third-party_workshop-contact': {
    name: claimsKeys.workshop.NAME,
    phoneNumber: [claimsKeys.workshop.PHONE, getPhoneValue],
    city: claimsKeys.workshop.CITY,
  },
  'liability_third-party_postal-code': {
    street: claimsKeys.workshop.LOCATION,
    postCode: claimsKeys.workshop.POST_CODE,
  },
  'liability_third-party_appointment-info': claimsKeys.APPOINTMENT_INFO,
  'liability_third-party_iban': {
    who: [claimsKeys.accountHolder.SALUTATION, getSalutationValue],
    name: claimsKeys.accountHolder.NAME,
    firstName: claimsKeys.accountHolder.FIRST_NAME,
    iban: [claimsKeys.accountHolder.IBAN_NUMBER, stripSpacesFromIban],
  },
};
