const styles = (theme) => ({
  topBarBox: {
    zIndex: 99,
    position: 'fixed',
    height: '80px',
    width: '100vw',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    ...theme.topBarBackground,
  },
  logo: {
    height: '100%',
    ...theme.logo,
  },
});

export default styles;
