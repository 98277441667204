import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgKontaktdatenDesUnfallbeteiligten } from '../../../helpers/IconLoader';
import { legalConsentProps } from '../../../models/panels';
import { getContactOfAccidentData } from '../../../helpers/formData/vehicleFormData';

const ContactOfAccidentDetails = ({ formData, localePath, className }, { t }) => {
  const {
    isCollisionFlow,
    isCompany,
    isOwnCar,
    firstName,
    lastName,
    phone,
    email,
  } = getContactOfAccidentData(formData);
  if (!(isCollisionFlow && !isOwnCar)) {
    return false;
  }

  return (
    <Details
      iconLeft={SvgKontaktdatenDesUnfallbeteiligten}
      summary={t(`${localePath}.contact-of-accident.title`)}
      className={className}
    >
      <p>
        {t(`${localePath}.contact-of-accident.content-name-${isCompany}`, {
          firstName,
          lastName,
        })}
      </p>
      <p>
        {t(`${localePath}.contact-of-accident.content-phone`, { phone })}
      </p>
      <p>
        {t(`${localePath}.contact-of-accident.content-email`, { email })}
      </p>
    </Details>
  );
};

ContactOfAccidentDetails.propTypes = legalConsentProps.sectionProps;
ContactOfAccidentDetails.defaultProps = legalConsentProps.sectionDefaults;

ContactOfAccidentDetails.contextTypes = {
  t: PropTypes.func,
};

export default ContactOfAccidentDetails;
