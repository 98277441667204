import { jss } from 'react-jss';

const transitionTime = '0.4s';
const topOffset = {
  desktop: {
    top: '91px',
  },
  mobile: {
    top: '71px',
  },
};

jss.createStyleSheet({
  '@keyframes form-slide-next': {
    from: {
      top: '30vh',
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },

  '@keyframes form-slide-next-reverse': {
    from: {
      top: '-30vh',
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}).attach();

const slide = {
  position: 'absolute',
  width: '100vw',
  left: '0vh',
  bottom: 0,
  overflow: 'auto',
  opacity: 1,
  /* https://developers.google.com/web/updates/2017/11/overscroll-behavior */
  overscrollBehaviorY: 'contain',
  transition: `top ${transitionTime} ease-in-out, opacity 0.3s ease-in-out`,
  ...topOffset.desktop,
};

const slideHigher = {
  ...slide,
  top: '75px',
};

const slideWithCookies = {
  ...slide,
  top: '204px',
};

const styles = theme => ({
  slide,
  slideHigher,
  slideWithCookies,

  slideCurrent: {
    ...slide,
    top: '-30vh',
    opacity: 0,
  },

  slideCurrentReverse: {
    ...slide,
    top: '30vh',
    opacity: 0,
  },

  slideNext: {
    ...slide,
    animation: `form-slide-next ${transitionTime} ease-in-out`,
  },

  slideNextReverse: {
    ...slide,
    animation: `form-slide-next-reverse ${transitionTime} ease-in-out`,
  },

  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div.esc_box': {
      width: '100%',
      paddingBottom: '96px',
      [`@media (max-width: ${theme.breakpoints.breakpointS})`]: {
        paddingBottom: '30px',
      },
    },
  },

  stepper: {
    margin: '0 auto',
    paddingBottom: '0 !important',
  },

  changeBoxSize: {
    paddingTop: '70px !important',
  },

  [`@media (max-width: ${theme.breakpoints.breakpointM})`]: {
    slide: {
      ...topOffset.mobile,
    },
    stepper: {
      marginBottom: '0 !important',
    },
  },
  footerForIe: {
    position: 'initial',
  },
  // eslint-disable-next-line
  ['@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)']: {
    // IE10+ CSS here
    footerForIe: {
      position: 'fixed',
      bottom: '0px',
      left: '0px',
      width: '100%',
    },
    addPaddingForIe: {
      paddingBottom: '700px !important',
    },
  },
});

export default styles;
