import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SummaryNotice from '../SummaryNotice/SummaryNotice';

class SummaryTodo extends React.PureComponent {
  componentDidMount() {
    this.props.dispatchAction('ADD_POLICE_REPORT_TODO_ITEM');
  }

  render() {
    return (
      <SummaryNotice {...this.props} />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchAction: (reduxAction) =>
    dispatch({ type: reduxAction }),
});

SummaryTodo.propTypes = {
  localePath: PropTypes.string,
  href: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  dispatchAction: PropTypes.func,
};

SummaryTodo.defaultProps = {
  localePath: '',
  href: '',
  classes: {},
  dispatchAction: () => {},
};

export { SummaryTodo as SummaryTodoPlain };
export default connect(null, mapDispatchToProps)(SummaryTodo);
